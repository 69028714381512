import css from "./registration.module.css";

export const Privacy = () => {
  return (
    <div className={css.privacy}>
      Wenn du ein Kundenkonto erstellst, stimmst du unseren{" "}
      <a href="/b/agb" target="_blank">
        AGBs
      </a>{" "}
      zu. Bitte lies unsere{" "}
      <a href="/b/datenschutz" target="_blank">
        Datenschutzerklärung
      </a>
      .
    </div>
  );
};
