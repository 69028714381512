import analytics from "@idg-data-analytics-platform/analytics";

export function trackControlAccount(
  accountId?: string,
  customerNumber?: string,
  convercusAccountId?: string,
) {
  analytics({
    event: "account_set",
    team: "control",
    properties: {
      type: "shop",
      login_status: accountId ? "logged in" : "logged out",
      account_id: accountId,
      customer_number: customerNumber,
      loyalty_id: convercusAccountId,
      is_club_member: !!convercusAccountId,
    },
  });
}
