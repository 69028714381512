import {
  ValidatedAddressFormFields,
  ValidatedAddressFormState,
} from "../interfaces/validatedAddressForm.ts";
import { InvoiceAddress } from "../interfaces/invoiceAddress.ts";
import { Title } from "../interfaces/title.ts";

export const createInvoiceAddress = (formState: ValidatedAddressFormState): InvoiceAddress => {
  const isGermanAddress = formState[ValidatedAddressFormFields.countryCode].value === "DE";

  const getFormattedAddress = () => {
    if (!isGermanAddress) {
      return formState[ValidatedAddressFormFields.addressLines].value.split("\n");
    } else {
      return [
        ...(formState[ValidatedAddressFormFields.additionalAddressInformation].value
          ? [`${formState[ValidatedAddressFormFields.additionalAddressInformation].value}`]
          : []),
        `${formState[ValidatedAddressFormFields.streetName].value} ${formState[ValidatedAddressFormFields.streetNr].value}`,
        `${formState[ValidatedAddressFormFields.zipCode].value} ${formState[ValidatedAddressFormFields.city].value}`,
      ];
    }
  };

  return {
    title: formState[ValidatedAddressFormFields.title].value as Title,
    firstName: formState[ValidatedAddressFormFields.firstName].value,
    lastName: formState[ValidatedAddressFormFields.lastName].value,
    additionalAddressInformation:
      formState[ValidatedAddressFormFields.additionalAddressInformation].value || undefined,
    streetName: formState[ValidatedAddressFormFields.streetName].value,
    streetNr: formState[ValidatedAddressFormFields.streetNr].value,
    zipCode: formState[ValidatedAddressFormFields.zipCode].value,
    city: formState[ValidatedAddressFormFields.city].value,
    formattedAddress: getFormattedAddress(),
    countryCode: formState[ValidatedAddressFormFields.countryCode].value,
    phoneNumber: formState[ValidatedAddressFormFields.phoneNumber].value || undefined,
  };
};
