import { useRef, useLayoutEffect } from "preact/hooks";
import css from "./navigationMobile.module.css";

const NavigationMobile = (props: MobileNavigationProps) => {
  const rootPath = "/c/account";
  const containerRef = useRef<HTMLDivElement>(null);
  const activeLinkRef = useRef<HTMLAnchorElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current && activeLinkRef.current) {
      const container = containerRef.current;
      const activeLink = activeLinkRef.current;

      const containerWidth = container.clientWidth;
      const linkOffsetLeft = activeLink.offsetLeft;
      const linkWidth = activeLink.clientWidth;

      container.scrollLeft = linkOffsetLeft - containerWidth / 2 + linkWidth / 2;
    }
  }, [props.currentPath]);

  return (
    <div className={css.root}>
      <div className={css.tabs}>
        <div className={css.tabsIndicator}></div>
        <div className={css.tabItems} ref={containerRef}>
          <a
            href={rootPath}
            className={rootPath === props.currentPath ? css.active : ""}
            ref={rootPath === props.currentPath ? activeLinkRef : null}
          >
            Mein INTERSPORT
          </a>
          {props.navItems.map((navItem) => (
            <a
              key={navItem.path}
              href={navItem.path}
              className={navItem.path === props.currentPath ? css.active : ""}
              ref={navItem.path === props.currentPath ? activeLinkRef : null}
            >
              {navItem.name}
            </a>
          ))}
          <button className={css.logoutButton} onClick={props.logoutHandler}>
            Abmelden
          </button>
        </div>
      </div>
    </div>
  );
};

interface MobileNavigationProps {
  navItems: { path: string; name: string }[];
  currentPath?: string;
  logoutHandler: () => void;
}

export { NavigationMobile };
