import css from "./login.module.css";
import { Button, Input, Text } from "@idg-web-platform/pulse";
import * as Auth from "@aws-amplify/auth";
import { login } from "../../config/cognito.ts";
import classNames from "classnames";
import spacings from "../../styles/spacings.module.css";
import displays from "../../styles/displays.module.css";
import { useState } from "preact/hooks";
import { validateEmail, validateNotEmpty } from "../../functions/validateFormFields.ts";
import { useManagedAlert } from "../../components/managedAlert/useManagedAlert.ts";
import { ManagedAlert } from "../../components/managedAlert/managedAlert.tsx";

LoginForm.tagName = "control-login-form";

export function LoginForm(loginFormProps: LoginFormProps) {
  return <LoginFormComponent {...loginFormProps} />;
}

function LoginFormComponent({ redirect, className }: LoginFormProps) {
  const [state, setState] = useState<LoginFormState>({});
  const { alert, showAlert, closeAlert } = useManagedAlert();

  function onChangeEmail(value: string, validate = true) {
    setState((prevState) => ({
      ...prevState,
      isEmailValid: validate ? validateEmail(value) : true,
      emailValue: value.trim().toLowerCase(),
    }));
    closeAlert();
    updateFormState();
  }

  function onChangePassword(value: string) {
    setState((prevState) => ({
      ...prevState,
      isPasswordValid: validateNotEmpty(value),
      passwordValue: value,
    }));
    closeAlert();
    updateFormState();
  }

  async function onSubmit(e: Event) {
    e.preventDefault();

    onChangeEmail(state.emailValue || "");
    onChangePassword(state.passwordValue || "");

    if (!state.isFormValid) {
      showAlert({
        headline: "Das hat leider nicht geklappt",
        description: "Bitte überprüfe deine Eingaben.",
        severity: "error",
      });
      return;
    }

    closeAlert();
    signIn();
  }

  function signIn() {
    setState((prevState) => ({ ...prevState, isSigningIn: true }));

    const handleError = (err: Error) => {
      if (err.name === "NotAuthorizedException") {
        showAlert({
          headline: "Das hat leider nicht geklappt",
          description: "Bitte prüfe deine E-Mail-Adresse und dein Passwort.",
          severity: "error",
        });
      } else if (err.name === "UserAlreadyAuthenticatedException") {
        console.warn("User is already authenticated. Signing out and trying again.");
        Auth.signOut().then(() => signIn());
        return;
      } else {
        console.error(err);
        showAlert({
          headline: "Das hat leider nicht geklappt",
          description: "Das hat leider nicht geklappt. Bitte versuche es später erneut.",
          severity: "error",
        });
      }

      setState((prevState) => ({ ...prevState, isSigningIn: false }));
    };

    login(state.emailValue!, state.passwordValue!, redirect).catch(handleError);
  }

  function updateFormState() {
    setState((prevState) => ({
      ...prevState,
      isFormValid: prevState.isEmailValid && prevState.isPasswordValid,
    }));
  }

  return (
    <>
      <div className={classNames(css.box, css.loginBox, className)}>
        <div className={css.content}>
          <form id={"login-form"} onSubmit={onSubmit} noValidate>
            <Text class={css.info}>
              Bereits <strong>Kunde</strong>
            </Text>
            <Input
              label="E-Mail-Adresse"
              id="email"
              type="email"
              onInput={(e) => onChangeEmail(e.currentTarget.value, false)}
              required
              valid={state.isEmailValid}
              errorMessage="Bitte gib eine gültige E-Mail-Adresse ein."
            />
            <Input
              id="password"
              label="Passwort"
              type="password"
              onInput={(e) => onChangePassword(e.currentTarget.value)}
              required
              valid={state.isPasswordValid}
              errorMessage="Bitte gib dein Passwort ein."
            />
            <a
              className={classNames(displays.displayBlock, spacings.marginBottomM)}
              href="/c/account/forgot-password"
            >
              Passwort vergessen
            </a>
            {/* @todo: refactor this file, move real button into form and remove this */}
            <button type="submit" style={{ display: "none" }} />
          </form>
          <ManagedAlert alert={alert} onClose={closeAlert} />
        </div>
        <div class={css.buttonContainer}>
          <Button
            class={css.button}
            onClick={onSubmit}
            level="primary"
            text="Anmelden"
            isLoading={state.isSigningIn}
          />
        </div>
      </div>
    </>
  );
}

export interface LoginFormProps {
  redirect: string;
  className?: string;
}

interface LoginFormState {
  isSigningIn?: boolean;
  isEmailValid?: boolean;
  emailValue?: string;
  isPasswordValid?: boolean;
  passwordValue?: string;
  isFormValid?: boolean;
}
