import { Alert, Button, Grid, Heading, IconInfo } from "@idg-web-platform/pulse";
import css from "./registration.module.css";
import { Privacy } from "./privacy.tsx";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect, useRef, useState } from "preact/hooks";
import { ValidatedAddressForm } from "../../components/validatedAddressForm/validatedAddressForm.tsx";
import {
  ValidatedAddressFormFields,
  ValidatedAddressFormRef,
} from "../../interfaces/validatedAddressForm.ts";
import { deletePreliminaryAccount } from "../../functions/deletePreliminaryAccount.ts";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";
import { createInvoiceAddress } from "../../functions/createInvoiceAddress.ts";
import { InvoiceAddress } from "../../interfaces/invoiceAddress.ts";
import { AccountData } from "../../interfaces/accountData.ts";
import { forceRefreshToken } from "../../config/cognito.ts";

interface InvoiceAddressPageProps {
  googleApiKey: string;
  countries: [string, string][];
}

InvoiceAddressPage.tagName = "control-registration-invoice-address-page";

export function InvoiceAddressPage(invoiceAddressPageProps: InvoiceAddressPageProps) {
  const validatedAddressFormRef = useRef<ValidatedAddressFormRef>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const defaultError = {
    headline: "Das hat leider nicht geklappt",
    description:
      "Wir haben einen technischen Fehler und möchten dich bitten, es später erneut zu versuchen.",
  };

  useEffect(() => {
    analyticsContentViewed("invoice_address");
  }, []);

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null); // Clear any previous error message

    const validationResult = await validatedAddressFormRef.current?.validateForm();

    if (validationResult) {
      const formState = validatedAddressFormRef.current!.getFormState();

      const invoiceAddress: InvoiceAddress = createInvoiceAddress(formState);
      const completeRegistration: AccountData = {
        invoiceAddress,
        birthday: formState[ValidatedAddressFormFields.birthday].value || undefined,
      };

      try {
        const response = await fetch("/api/c/account/registration/invoice-address", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(completeRegistration),
        });
        // refresh auth cookie to fetch the latest account state
        await forceRefreshToken();

        if (response.ok) {
          document.location.href = "/c/account";
        } else {
          const errorMessage = await response.text();
          setErrorMessage(errorMessage || defaultError.description);
        }
      } catch (error) {
        setErrorMessage(defaultError.description);
        console.error(error);
      }
    }
    setIsSubmitting(false);
  };

  const onAbort = async () => {
    setIsCancelling(true);

    try {
      await deletePreliminaryAccount();
      document.location.href = "/c/account/login";
    } catch (e) {
      setErrorMessage(defaultError.description);
      console.error(e);
    }

    setIsCancelling(false);
  };

  return (
    <>
      <Grid>
        <div className={css.registrationPageContainer}>
          <Heading sizeStep={2} element="h1" text="Rechnungsadresse" />
          <form noValidate onSubmit={onSubmit}>
            <ValidatedAddressForm
              ref={validatedAddressFormRef}
              id="invoice"
              countries={invoiceAddressPageProps.countries}
              data={{ countryCode: "DE" }}
              googleApiKey={invoiceAddressPageProps.googleApiKey}
            />

            <Privacy />

            {errorMessage && (
              <Alert
                type="error"
                headline={defaultError.headline}
                description={errorMessage}
                onClose={() => setErrorMessage(null)}
                class={margins.pulseMarginBottomM}
              />
            )}

            <Button
              type="submit"
              class={css.registrationButton}
              text="Kundenkonto anlegen"
              level="primary"
              isLoading={isSubmitting}
            />
            <Button
              type="button"
              class={css.registrationButton}
              text="Abbrechen"
              level="secondary"
              isLoading={isCancelling}
              onClick={onAbort}
            />
          </form>
          <div className={css.accountCreationInfo}>
            <IconInfo />
            <div>
              <strong>Neu</strong> hier: Wir legen erst ein Kundenkonto für dich an, wenn du deine
              Adresse gespeichert hast.
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}
