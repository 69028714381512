import { Alert, Input, Textarea, Select } from "@idg-web-platform/pulse";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";
import { TitleSelectOptions } from "../../constants/titleSelectOptions.ts";
import css from "./validatedAddressForm.module.css";
import {
  ValidatedAddressFormFields,
  ValidatedAddressFormProps,
  ValidatedAddressFormRef,
  ValidatedAddressFormState,
  ValidatedAddressFormLabels,
  ValidatedAddressFormErrorMessages,
  initializeFormState,
} from "../../interfaces/validatedAddressForm.ts";
import {
  validatePhoneNumber,
  validateDateOfBirth,
  validateZipCode,
  validateNotEmpty,
} from "../../functions/validateFormFields.ts";
import { useImperativeHandle, useState } from "preact/hooks";
import { forwardRef } from "preact/compat";
import inputs from "@idg-web-platform/pulse/cssModules/inputs.module.css";
import classNames from "classnames";
import {
  validateGermanAddress,
  validateEUAddress,
  ValidationResult,
  AddressData,
  EUAddress,
} from "../../functions/validateAddress.ts";

export const ValidatedAddressForm = forwardRef<ValidatedAddressFormRef, ValidatedAddressFormProps>(
  (props, ref) => {
    const getId = (id: string) => `validated-form-${id}`;
    const isGermany = (countryCode?: string) => countryCode === "DE";
    const [formState, setFormState] = useState<ValidatedAddressFormState>(
      initializeFormState(props.data),
    );
    const [addressAlert, setAddressAlert] = useState<{
      type: "success" | "error";
      message: string;
    } | null>(null);

    const isFieldValid = (field: ValidatedAddressFormFields) => {
      // initial state is undefined, so we need to check for false
      return formState[field].isValid !== false;
    };

    const updateFormState = (
      field: ValidatedAddressFormFields,
      value: string,
      isValid: boolean,
    ) => {
      setFormState((prevState) => ({
        ...prevState,
        [field]: {
          value,
          isValid,
        },
      }));
    };

    const storeWithoutValidation = (key: ValidatedAddressFormFields, value: string) => {
      updateFormState(key, value, true);
    };

    const validateField = (field: ValidatedAddressFormFields, value: string): boolean => {
      const isGermanAddress = isGermany(formState[ValidatedAddressFormFields.countryCode].value);

      switch (field) {
        case ValidatedAddressFormFields.title:
        case ValidatedAddressFormFields.firstName:
        case ValidatedAddressFormFields.lastName:
        case ValidatedAddressFormFields.countryCode:
          return validateNotEmpty(value);

        case ValidatedAddressFormFields.streetName:
        case ValidatedAddressFormFields.city:
          return isGermanAddress ? validateNotEmpty(value) : true;

        case ValidatedAddressFormFields.addressLines:
          return isGermanAddress ? true : validateNotEmpty(value);

        case ValidatedAddressFormFields.zipCode:
          return isGermanAddress ? validateZipCode(value) : true;

        case ValidatedAddressFormFields.birthday:
          return value ? validateDateOfBirth(value) : true;

        case ValidatedAddressFormFields.phoneNumber:
          return value ? validatePhoneNumber(value) : true;

        default:
          return true;
      }
    };

    const validateAndUpdateField = (field: ValidatedAddressFormFields, value: string) => {
      updateFormState(field, value, validateField(field, value));
    };

    // exposed function to validate the form fields from the parent component
    useImperativeHandle(ref, () => ({
      getFormState: () => {
        return formState;
      },
      validateForm: async () => {
        let isFormValid = true;
        const updatedState = { ...formState };
        setAddressAlert(null); // Reset any previous alerts

        // Perform local validation
        Object.keys(formState).forEach((fieldKey) => {
          const field = fieldKey as ValidatedAddressFormFields;
          const isValid = validateField(field, formState[field].value);
          updatedState[field] = {
            ...formState[field],
            isValid,
          };
          if (!isValid) {
            isFormValid = false;
          }
        });

        // Proceed to address validation if local validation passed
        if (isFormValid) {
          const countryCode = formState[ValidatedAddressFormFields.countryCode].value;

          try {
            if (isGermany(countryCode)) {
              // Prepare German address data
              const addressData: AddressData = {
                additionalAddressInformation:
                  formState[ValidatedAddressFormFields.additionalAddressInformation].value,
                street: formState[ValidatedAddressFormFields.streetName].value,
                streetNumber: formState[ValidatedAddressFormFields.streetNr].value,
                zipCode: formState[ValidatedAddressFormFields.zipCode].value,
                city: formState[ValidatedAddressFormFields.city].value,
              };

              // Validate German address
              const validatedAddress = await validateGermanAddress(props.googleApiKey, addressData);

              // Handle validation results
              if (validatedAddress.validation === ValidationResult.FAILED) {
                // Address validation failed
                updatedState[ValidatedAddressFormFields.streetName].isValid = false;
                updatedState[ValidatedAddressFormFields.streetNr].isValid = false;
                updatedState[ValidatedAddressFormFields.zipCode].isValid = false;
                updatedState[ValidatedAddressFormFields.city].isValid = false;

                setAddressAlert({
                  type: "error",
                  message:
                    "Wir konnten deine eingegebene Adresse nicht finden, bitte vervollständige deine Angaben.",
                });

                isFormValid = false;
              } else {
                // corrected or confirmed
                updatedState[ValidatedAddressFormFields.streetName].value =
                  validatedAddress.addressData.street;
                updatedState[ValidatedAddressFormFields.streetNr].value =
                  validatedAddress.addressData.streetNumber || "";
                updatedState[ValidatedAddressFormFields.zipCode].value =
                  validatedAddress.addressData.zipCode;
                updatedState[ValidatedAddressFormFields.city].value =
                  validatedAddress.addressData.city;

                if (validatedAddress.validation === ValidationResult.CORRECTED) {
                  setAddressAlert({
                    type: "success",
                    message:
                      "Deine Adresse wurde vervollständigt. Bitte überprüfe sie und fahre fort.",
                  });

                  isFormValid = false;
                }
              }
            } else {
              // Prepare EU address data
              const euAddress: EUAddress = {
                lines: formState[ValidatedAddressFormFields.addressLines].value.split("\n"),
                countryCode: countryCode,
              };

              // Validate EU address
              const validatedAddress = await validateEUAddress(props.googleApiKey, euAddress);

              // Handle validation results
              if (validatedAddress.validation === ValidationResult.FAILED) {
                updatedState[ValidatedAddressFormFields.addressLines].isValid = false;

                setAddressAlert({
                  type: "error",
                  message:
                    "Wir konnten deine eingegebene Adresse nicht finden, bitte vervollständige deine Angaben.",
                });

                isFormValid = false;
              } else {
                // confirmed or corrected
                // if corrected, we procced with the corrected address
                updatedState[ValidatedAddressFormFields.addressLines].value =
                  validatedAddress.formattedAddress.join("\n");
                updatedState[ValidatedAddressFormFields.city].value =
                  validatedAddress.addressData.city;
                updatedState[ValidatedAddressFormFields.zipCode].value =
                  validatedAddress.addressData.zipCode;
                updatedState[ValidatedAddressFormFields.streetName].value =
                  validatedAddress.addressData.street;
                updatedState[ValidatedAddressFormFields.streetNr].value =
                  validatedAddress.addressData.streetNumber || "";
              }
            }
          } catch (error) {
            // Handle errors from the API call
            console.error("Address validation error:", error);
            setAddressAlert({
              type: "error",
              message:
                "Es gab ein Problem bei der Überprüfung deiner Adresse. Bitte versuche es später erneut.",
            });
            isFormValid = false;
          }
        }

        // Update form state and return validation result
        setFormState(updatedState);
        return isFormValid;
      },
    }));

    const renderGermanyAddressField = () => (
      <>
        <div className={css.validatedAddressFormLineMultiple}>
          <div className={css.validatedAddressFormInput70}>
            <Input
              id={getId(ValidatedAddressFormFields.streetName)}
              label={ValidatedAddressFormLabels.streetName}
              type="text"
              value={formState[ValidatedAddressFormFields.streetName].value}
              onInput={(e) =>
                validateAndUpdateField(ValidatedAddressFormFields.streetName, e.currentTarget.value)
              }
              valid={formState[ValidatedAddressFormFields.streetName].isValid}
              required
              autocomplete="billing address-line1"
              errorMessage={
                ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.streetName]
              }
            />
          </div>
          <div className={css.validatedAddressFormInput30}>
            <Input
              id={getId(ValidatedAddressFormFields.streetNr)}
              label={ValidatedAddressFormLabels.streetNr}
              type="text"
              value={formState[ValidatedAddressFormFields.streetNr].value}
              onInput={(e) =>
                validateAndUpdateField(ValidatedAddressFormFields.streetNr, e.currentTarget.value)
              }
              valid={formState[ValidatedAddressFormFields.streetNr].isValid}
              required // this is only for the UI, there are also some streets without numbers
              autocomplete="billing address-line2"
              errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.streetNr]}
            />
          </div>
        </div>

        <Input
          id={getId(ValidatedAddressFormFields.additionalAddressInformation)}
          label={ValidatedAddressFormLabels.additionalAddressInformation}
          type="text"
          value={formState[ValidatedAddressFormFields.additionalAddressInformation].value}
          onInput={(e) =>
            storeWithoutValidation(
              ValidatedAddressFormFields.additionalAddressInformation,
              e.currentTarget.value,
            )
          }
          valid={formState[ValidatedAddressFormFields.additionalAddressInformation].isValid}
          autocomplete="billing address-line3"
          errorMessage={
            ValidatedAddressFormErrorMessages[
              ValidatedAddressFormFields.additionalAddressInformation
            ]
          }
        />

        <div className={css.validatedAddressFormLineMultiple}>
          <div className={css.validatedAddressFormInput30}>
            <Input
              id={getId(ValidatedAddressFormFields.zipCode)}
              label={ValidatedAddressFormLabels.zipCode}
              type="text"
              value={formState[ValidatedAddressFormFields.zipCode].value}
              onInput={(e) =>
                storeWithoutValidation(ValidatedAddressFormFields.zipCode, e.currentTarget.value)
              }
              valid={formState[ValidatedAddressFormFields.zipCode].isValid}
              required
              autocomplete="billing postal-code"
              errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.zipCode]}
            />
          </div>
          <div className={css.validatedAddressFormInput70}>
            <Input
              id={getId(ValidatedAddressFormFields.city)}
              label={ValidatedAddressFormLabels.city}
              type="text"
              value={formState[ValidatedAddressFormFields.city].value}
              onInput={(e) =>
                validateAndUpdateField(ValidatedAddressFormFields.city, e.currentTarget.value)
              }
              valid={formState[ValidatedAddressFormFields.city].isValid}
              required
              autocomplete="billing address-level2"
              errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.city]}
            />
          </div>
        </div>
      </>
    );

    const renderNonGermanyAddressFields = () => (
      <div className={inputs.pulseInputGroup}>
        <Textarea
          rows={3}
          className={classNames({
            [inputs.pulseInputField]: true,
            [inputs.pulseInputFieldInvalid]: !isFieldValid(ValidatedAddressFormFields.addressLines),
          })}
          id={getId(ValidatedAddressFormFields.addressLines)}
          label={ValidatedAddressFormLabels.addressLines}
          name={getId(ValidatedAddressFormFields.addressLines)}
          value={formState[ValidatedAddressFormFields.addressLines].value}
          valid={formState[ValidatedAddressFormFields.addressLines].isValid}
          required
          autocomplete="billing address-line1"
          onInput={(e) => {
            const value = (e.target as HTMLTextAreaElement).value;
            validateAndUpdateField(ValidatedAddressFormFields.addressLines, value);
          }}
          errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.addressLines]}
        />
      </div>
    );

    return (
      <div data-dd-privacy="mask">
        <Select
          id={getId(ValidatedAddressFormFields.title)}
          label={ValidatedAddressFormLabels.title}
          options={[{ value: "", label: "" }, ...TitleSelectOptions]}
          className={css.validatedAddressFormTitle}
          value={formState[ValidatedAddressFormFields.title].value}
          onInput={(e) =>
            validateAndUpdateField(ValidatedAddressFormFields.title, e.currentTarget.value)
          }
          valid={formState[ValidatedAddressFormFields.title].isValid}
          required
          autocomplete="billing honorific-prefix"
          errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.title]}
        />

        <div className={css.validatedAddressFormLineMultipleM}>
          <div className={css.validatedAddressFormInputM50}>
            <Input
              id={getId(ValidatedAddressFormFields.firstName)}
              label={ValidatedAddressFormLabels.firstName}
              value={formState[ValidatedAddressFormFields.firstName].value}
              onInput={(e) =>
                validateAndUpdateField(ValidatedAddressFormFields.firstName, e.currentTarget.value)
              }
              valid={formState[ValidatedAddressFormFields.firstName].isValid}
              required
              autocomplete="billing given-name"
              errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.firstName]}
            />
          </div>
          <div className={css.validatedAddressFormInputM50}>
            <Input
              id={getId(ValidatedAddressFormFields.lastName)}
              label={ValidatedAddressFormLabels.lastName}
              type="text"
              value={formState[ValidatedAddressFormFields.lastName].value}
              onInput={(e) =>
                validateAndUpdateField(ValidatedAddressFormFields.lastName, e.currentTarget.value)
              }
              valid={formState[ValidatedAddressFormFields.lastName].isValid}
              required
              autocomplete="billing family-name"
              errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.lastName]}
            />
          </div>
        </div>

        <Select
          id={getId(ValidatedAddressFormFields.countryCode)}
          label={ValidatedAddressFormLabels.countryCode}
          value={formState[ValidatedAddressFormFields.countryCode].value}
          onInput={(e) => {
            validateAndUpdateField(ValidatedAddressFormFields.countryCode, e.currentTarget.value);
          }}
          options={props.countries.map(([code, name]) => ({
            value: code,
            label: name,
          }))}
          valid={formState[ValidatedAddressFormFields.countryCode].isValid}
          required
          autocomplete="billing country"
          errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.countryCode]}
        />

        {isGermany(formState[ValidatedAddressFormFields.countryCode].value)
          ? renderGermanyAddressField()
          : renderNonGermanyAddressFields()}

        {addressAlert && (
          <Alert
            class={margins.pulseMarginBottomM}
            headline={
              addressAlert.type === "success" ? "Adresse vervollständigt" : "Bitte vervollständigen"
            }
            description={addressAlert.message}
            type={addressAlert.type}
            onClose={() => {
              setAddressAlert(null);
            }}
          />
        )}

        <Input
          id={getId(ValidatedAddressFormFields.birthday)}
          label={ValidatedAddressFormLabels.birthday}
          type="date"
          value={formState[ValidatedAddressFormFields.birthday].value}
          onInput={(e) =>
            validateAndUpdateField(ValidatedAddressFormFields.birthday, e.currentTarget.value)
          }
          valid={formState[ValidatedAddressFormFields.birthday].isValid}
          autocomplete="bday"
          errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.birthday]}
        />

        <Input
          id={getId(ValidatedAddressFormFields.phoneNumber)}
          label={ValidatedAddressFormLabels.phoneNumber}
          type="text"
          value={formState[ValidatedAddressFormFields.phoneNumber].value}
          onInput={(e) =>
            validateAndUpdateField(ValidatedAddressFormFields.phoneNumber, e.currentTarget.value)
          }
          valid={formState[ValidatedAddressFormFields.phoneNumber].isValid}
          autocomplete="billing tel"
          errorMessage={ValidatedAddressFormErrorMessages[ValidatedAddressFormFields.phoneNumber]}
        />
      </div>
    );
  },
);
