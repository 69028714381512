import { useEffect, useState, useRef } from "preact/hooks";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import {
  Alert,
  Button,
  Grid,
  Heading,
  Input,
  Select,
  Text,
  Textarea,
  IconPhone,
} from "@idg-web-platform/pulse";
import css from "./contactPage.module.css";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";
import {
  ContactFormErrorMessages,
  ContactFormFields,
  ContactFormIssues,
  ContactFormLabels,
  ContactFormState,
  initializeContactFormState,
} from "./contactForm.ts";
import { validateNotEmpty, validateEmail } from "../../functions/validateFormFields.ts";
import { Title } from "../../interfaces/title.ts";
import { TitleSelectOptions } from "../../constants/titleSelectOptions.ts";

declare global {
  interface Window {
    grecaptcha?: {
      ready(cb: () => void): void;
      execute(siteKey: string, opts: { action: string }): Promise<string>;
    };
  }
}

interface ContactPageProps {
  reCaptchaSiteKey: string;
  sent?: boolean;
  sentIssue?: string;
  contact?: Contact;
}

export type Contact = {
  title?: Title;
  firstName?: string;
  lastName?: string;
  email?: string;
};

ContactPage.tagName = "control-contact-page";

export function ContactPage({ reCaptchaSiteKey, sent, sentIssue, contact }: ContactPageProps) {
  const getId = (id: string) => `contact-form-${id}`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formState, setFormState] = useState<ContactFormState>(initializeContactFormState(contact));

  const formRef = useRef<HTMLFormElement>(null);

  const isFieldValid = (field: ContactFormFields) => {
    return formState[field].isValid !== false;
  };

  const updateFormState = (field: ContactFormFields, value: string, isValid: boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: {
        value,
        isValid,
      },
    }));
  };

  const storeWithoutValidation = (field: ContactFormFields, value: string) => {
    updateFormState(field, value, true);
  };

  const validateForm = (key: ContactFormFields, value: string) => {
    switch (key) {
      case ContactFormFields.email:
        return validateEmail(value);
      case ContactFormFields.description:
        return validateNotEmpty(value) && value.length >= 20 && value.length <= 500;
      case ContactFormFields.title:
      case ContactFormFields.firstName:
      case ContactFormFields.lastName:
      case ContactFormFields.issue:
        return validateNotEmpty(value);
      default:
        return true;
    }
  };

  const validateAndUpdateField = (field: ContactFormFields, value: string) => {
    updateFormState(field, value, validateForm(field, value));
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    setIsSubmitting(true);

    let isFormValid = true;
    const updatedFormState = { ...formState };

    for (const key in updatedFormState) {
      const field = key as ContactFormFields;
      const fieldValue = updatedFormState[field].value;
      const valid = validateForm(field, fieldValue);
      updatedFormState[field].isValid = valid;
      if (!valid) {
        isFormValid = false;
      }
    }

    setFormState(updatedFormState);

    if (!isFormValid) {
      setIsSubmitting(false);
      return;
    }

    if (window.grecaptcha && reCaptchaSiteKey) {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha!.execute(reCaptchaSiteKey, {
            action: "contact_form",
          });
          const form = formRef.current!;

          let tokenInput = form.querySelector('input[name="recaptchaToken"]') as HTMLInputElement;
          if (!tokenInput) {
            tokenInput = document.createElement("input");
            tokenInput.type = "hidden";
            tokenInput.name = "recaptchaToken";
            form.appendChild(tokenInput);
          }
          tokenInput.value = token;

          form.submit();
        } catch (error) {
          sent = false;
          console.error("Error while executing reCaptcha", error);
          setIsSubmitting(false);
        }
      });
    } else {
      // No grecaptcha available, send form without token
      const form = e.currentTarget as HTMLFormElement;
      form.submit();
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [reCaptchaSiteKey]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const issueKey = urlParams.get("issue") as keyof typeof ContactFormIssues;

    if (issueKey && issueKey in ContactFormIssues) {
      const issueValue = ContactFormIssues[issueKey];
      updateFormState(ContactFormFields.issue, issueValue, true);
    }
  }, []);

  useEffect(() => {
    if (sent) {
      analyticsContentViewed("generic_contact_requested", sentIssue);
    } else {
      analyticsContentViewed("contact");
    }
  }, [sent, sentIssue]);

  return (
    <>
      <Grid>
        <section className={css.pageContainer}>
          <Heading sizeStep={2} text="Hilfe & Kontakt" />
          <Text fontStyle="bold">Bei Fragen hilft dir unser Kundenservice</Text>

          {sent !== null ? (
            <Alert
              type={sent ? "success" : "error"}
              headline={sent ? "Vielen Dank" : "Fehler beim Senden"}
              description={
                sent
                  ? "Wir haben dein Anliegen erhalten."
                  : "Beim Senden deiner Nachricht ist ein Fehler aufgetreten. Bitte versuche es später erneut."
              }
              showCloseButton={false}
            />
          ) : (
            <form
              ref={formRef}
              method="POST"
              action="/api/c/account/contact"
              noValidate
              onSubmit={handleSubmit}
            >
              <Select
                id={getId(ContactFormFields.title)}
                name={getId(ContactFormFields.title)}
                label={ContactFormLabels.title}
                options={[{ value: "", label: "" }, ...TitleSelectOptions]}
                className={css.contactFormTitle}
                value={formState[ContactFormFields.title].value}
                onInput={(e) =>
                  validateAndUpdateField(ContactFormFields.title, e.currentTarget.value)
                }
                valid={isFieldValid(ContactFormFields.title)}
                required
                autocomplete="honorific-prefix"
                errorMessage={ContactFormErrorMessages[ContactFormFields.title]}
              />

              <div className={css.contactFormName}>
                <Input
                  id={getId(ContactFormFields.firstName)}
                  name={getId(ContactFormFields.firstName)}
                  label={ContactFormLabels.firstName}
                  value={formState[ContactFormFields.firstName].value}
                  onInput={(e) =>
                    validateAndUpdateField(ContactFormFields.firstName, e.currentTarget.value)
                  }
                  valid={isFieldValid(ContactFormFields.firstName)}
                  required
                  autocomplete="given-name"
                  errorMessage={ContactFormErrorMessages[ContactFormFields.firstName]}
                />

                <Input
                  id={getId(ContactFormFields.lastName)}
                  name={getId(ContactFormFields.lastName)}
                  label={ContactFormLabels.lastName}
                  value={formState[ContactFormFields.lastName].value}
                  onInput={(e) =>
                    validateAndUpdateField(ContactFormFields.lastName, e.currentTarget.value)
                  }
                  valid={isFieldValid(ContactFormFields.lastName)}
                  required
                  autocomplete="family-name"
                  errorMessage={ContactFormErrorMessages[ContactFormFields.lastName]}
                />
              </div>

              <Input
                id={getId(ContactFormFields.email)}
                name={getId(ContactFormFields.email)}
                label={ContactFormLabels.email}
                type="email"
                value={formState[ContactFormFields.email].value}
                onInput={(e) =>
                  validateAndUpdateField(ContactFormFields.email, e.currentTarget.value)
                }
                valid={isFieldValid(ContactFormFields.email)}
                required
                autocomplete="email"
                errorMessage={ContactFormErrorMessages[ContactFormFields.email]}
              />

              <Select
                id={getId(ContactFormFields.issue)}
                name={getId(ContactFormFields.issue)}
                label={ContactFormLabels.issue}
                options={[
                  { value: "", label: "Bitte wähle dein Anliegen" },
                  ...Object.values(ContactFormIssues).map((label) => ({
                    value: label,
                    label,
                  })),
                ]}
                value={formState[ContactFormFields.issue].value}
                onInput={(e) =>
                  validateAndUpdateField(ContactFormFields.issue, e.currentTarget.value)
                }
                valid={isFieldValid(ContactFormFields.issue)}
                required
                errorMessage={ContactFormErrorMessages[ContactFormFields.issue]}
              />

              <Input
                id={getId(ContactFormFields.orderNumber)}
                name={getId(ContactFormFields.orderNumber)}
                label={ContactFormLabels.orderNumber}
                type="text"
                value={formState[ContactFormFields.orderNumber].value}
                onInput={(e) =>
                  storeWithoutValidation(ContactFormFields.orderNumber, e.currentTarget.value)
                }
              />

              <Text fontStyle="prose" sizeStep={0}>
                Bitte beschreibe dein Anliegen und ergänze ggf. deine Kundennummer:
              </Text>

              <Textarea
                id={getId(ContactFormFields.description)}
                name={getId(ContactFormFields.description)}
                label={ContactFormLabels.description}
                value={formState[ContactFormFields.description].value}
                onInput={(e) =>
                  storeWithoutValidation(ContactFormFields.description, e.currentTarget.value)
                }
                valid={isFieldValid(ContactFormFields.description)}
                required
                rows={5}
                errorMessage={ContactFormErrorMessages[ContactFormFields.description]}
              />

              <Text fontStyle="prose" sizeStep={0}>
                Mit dem Abschicken erklärst du, dass du unsere Datenschutzerklärung gelesen hast und
                zustimmst.
              </Text>

              <Button
                id={getId("submit")}
                type="submit"
                text="Abschicken"
                level="primary"
                class={margins.pulseMarginBottomM}
                isLoading={isSubmitting}
              />

              <div className={css.contactBox}>
                <div>
                  <IconPhone />
                </div>
                <div>
                  <Text fontStyle="bold" sizeStep={0} removeMargin={true}>
                    Telefonisch erreichst du uns:
                  </Text>

                  <Text fontStyle="prose" sizeStep={1} removeMargin={true}>
                    +49 (0)7131 12 30 090
                  </Text>
                  <Text
                    fontStyle="default"
                    sizeStep={-1}
                    removeMargin={true}
                    class={margins.pulseMarginBottom2xs}
                  >
                    Kostenlos aus dem dt. Festnetz, Mobilfunk-Tarife können abweichen.
                  </Text>
                  <Text fontStyle="bold" sizeStep={0} removeMargin={true}>
                    Montags - Freitag:
                  </Text>
                  <Text
                    fontStyle="prose"
                    sizeStep={0}
                    removeMargin={true}
                    class={margins.pulseMarginBottom2xs}
                  >
                    08:00 - 18:00 Uhr
                  </Text>
                  <Text fontStyle="bold" sizeStep={0} removeMargin={true}>
                    Samstags:
                  </Text>
                  <Text fontStyle="prose" sizeStep={0}>
                    09:00 - 15:00 Uhr
                  </Text>
                </div>
              </div>
            </form>
          )}
        </section>
      </Grid>
    </>
  );
}
