import * as Auth from "@aws-amplify/auth";

export enum DeletePreliminaryAccountEventListenerStatus {
  SUCCESS = "SUCCESS",
  ERROR_CANNOT_DELETE_COMPLETE_ACCOUNT = "ERROR_CANNOT_DELETE_COMPLETE_ACCOUNT",
}

export interface DeletePreliminaryAccountApiResponse {
  code: DeletePreliminaryAccountEventListenerStatus;
  message: string;
}

export const deletePreliminaryAccount =
  async (): Promise<DeletePreliminaryAccountEventListenerStatus> => {
    const response = await fetch("/api/c/account/preliminary-account", {
      method: "DELETE",
    });

    if (!response.ok) throw new Error(`${response.status} - ${response.statusText}`);

    const body = (await response.json()) as DeletePreliminaryAccountApiResponse;

    switch (body.code) {
      case DeletePreliminaryAccountEventListenerStatus.SUCCESS:
        await Auth.signOut();
        break;
      case DeletePreliminaryAccountEventListenerStatus.ERROR_CANNOT_DELETE_COMPLETE_ACCOUNT:
        break;
    }
    return body.code;
  };
