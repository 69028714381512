import { ValidatedAddressFormFields } from "../../interfaces/validatedAddressForm.ts";
import { Contact } from "./contactPage.tsx";

export const ContactFormFields = {
  title: "title",
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  issue: "issue",
  orderNumber: "orderNumber",
  description: "description",
};

export type ContactFormFields = (typeof ContactFormFields)[keyof typeof ContactFormFields];

export const ContactFormLabels = {
  [ContactFormFields.title]: "Anrede",
  [ContactFormFields.firstName]: "Vorname",
  [ValidatedAddressFormFields.lastName]: "Nachname",
  [ContactFormFields.email]: "E-Mail-Adresse",
  [ContactFormFields.issue]: "Zu welchem Thema hast du eine Frage?",
  [ContactFormFields.orderNumber]: "Bestellnummer",
  [ContactFormFields.description]: "Beschreibung (max. 500 Zeichen)",
};

export const ContactFormErrorMessages: { [key in ContactFormFields]?: string } = {
  [ContactFormFields.title]: "Bitte wähle eine Anrede aus",
  [ContactFormFields.firstName]: "Bitte gib deinen Vornamen ein",
  [ContactFormFields.lastName]: "Bitte gib deinen Nachnamen ein",
  [ContactFormFields.email]: "Bitte gib deine E-Mail-Adresse ein",
  [ContactFormFields.issue]: "Bitte wähle ein Thema aus",
  [ContactFormFields.description]: "Bitte gib eine Beschreibung zwischen 20 und 500 Zeichen ein",
};

export type ContactFormState = Record<
  ContactFormFields,
  {
    value: string;
    isValid: boolean | undefined;
  }
>;

export function initializeContactFormState(contact?: Contact): () => ContactFormState {
  return () => ({
    [ContactFormFields.title]: {
      value: contact?.title || "",
      isValid: !!contact?.title || undefined,
    },
    [ContactFormFields.firstName]: {
      value: contact?.firstName || "",
      isValid: !!contact?.firstName || undefined,
    },
    [ContactFormFields.lastName]: {
      value: contact?.lastName || "",
      isValid: !!contact?.lastName || undefined,
    },
    [ContactFormFields.email]: {
      value: contact?.email || "",
      isValid: !!contact?.email || undefined,
    },
    [ContactFormFields.issue]: {
      value: "",
      isValid: undefined,
    },
    [ContactFormFields.orderNumber]: {
      value: "",
      isValid: undefined,
    },
    [ContactFormFields.description]: {
      value: "",
      isValid: undefined,
    },
  });
}

export const ContactFormIssues = {
  orderProcess: "Bestellprozess",
  customerAccount: "Kundenkonto",
  accountDeletion: "Kontolöschung",
  delivery: "Lieferung",
  payment: "Zahlung",
  club: "Club",
  complaint: "Reklamation",
  cancellation: "Widerruf",
  return: "Retoure",
  assortment: "Sortiment",
  company: "Unternehmen",
  other: "Sonstiges",
  feedback: "Lob und Kritik",
} as const;
