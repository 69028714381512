import { Grid } from "@idg-web-platform/pulse";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";
import css from "./helpPage.module.css";
import { useEffect } from "preact/hooks";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";

HelpPage.tagName = "control-help";

export function HelpPage() {
  useEffect(() => {
    analyticsContentViewed("help");
  }, []);

  const trackClick = (anchor: string) => {
    analyticsContentViewed("help", anchor);
  };

  return (
    <Grid>
      <section className="mono-1-narrow">
        <h1>Wie können wir dir helfen?</h1>
        <p>Bitte wähle eines der Themen aus, bei dem du Hilfe benötigst.</p>

        <nav className={margins.pulseMarginBottomS}>
          <ul className={css.nav}>
            <li className={css.navItem}>
              <a
                href="#mein-intersport"
                onClick={() => {
                  trackClick("Mein INTERSPORT");
                }}
              >
                Antworten auf Fragen zu Mein INTERSPORT
              </a>
            </li>
            <li className={css.navItem}>
              <a
                href="#club"
                onClick={() => {
                  trackClick("CLUB");
                }}
              >
                Antworten zum INTERSPORT CLUB
              </a>
            </li>
            <li className={css.navItem}>
              <a
                href="#bestellung"
                onClick={() => {
                  trackClick("Bestellung");
                }}
              >
                Antworten auf Fragen rund um die Lieferung deiner Bestellung
              </a>
            </li>
            <li className={css.navItem}>
              <a
                href="#ruecksendung"
                onClick={() => {
                  trackClick("Rücksendung");
                }}
              >
                Antworten auf Fragen rund um die Rücksendung
              </a>
            </li>
            <li className={css.navItem}>
              <a
                href="#reklamation"
                onClick={() => {
                  trackClick("Reklamation");
                }}
              >
                Antworten auf Fragen zur Reklamation
              </a>
            </li>
            <li className={css.navItem}>
              <a
                href="#zahlung"
                onClick={() => {
                  trackClick("Zahlung");
                }}
              >
                Antworten auf Fragen rund um die Zahlung
              </a>
            </li>
          </ul>
        </nav>

        <div className={css.divider}></div>

        <h2 id="mein-intersport">Antworten auf Fragen zu Mein INTERSPORT</h2>

        <h3>Kann ich meine Bestellung im Nachgang noch stornieren?</h3>

        <p>
          Sobald du deine Bestellung in unserem Shop aufgegeben hast und diese noch nicht in
          Bearbeitung ist, können wir deine Bestellung noch stornieren. Nimm bitte schnellstmöglich
          Kontakt zu uns auf. Sollte deine Bestellung bereits auf dem Weg zu dir sein, hast du die
          Möglichkeit, die Annahme der Sendung zu verweigern oder diese kostenlos an uns
          zurückzusenden.
        </p>

        <h3>Warum wurde meine Bestellung von INTERSPORT storniert?</h3>

        <p>
          Wir bedauern, wenn deine Bestellung / Bestellposition storniert wurde. Der INTERSPORT
          Onlineshop arbeitet mit unseren Händlern vor Ort zusammen, d.h. die Waren werden von
          unseren Händlern aus ganz Deutschland versendet. Dadurch kann sich die Verfügbarkeit auf
          Grund von Tagesverkäufen leicht ändern. In diesem Fall müssen wir leider einen endgültigen
          Storno buchen und teilen dir dieses dann auch per E-Mail entsprechend mit. Du kannst den
          Status deiner Bestellung jederzeit unter{" "}
          <a href="/c/orders">Mein INTERSPORT - Bestellungen</a> einsehen.
        </p>

        <h3>Kann ich meine Bestellung im Nachhinein noch ändern?</h3>

        <p>
          Da wir deine Bestellungen so schnell wie möglich bearbeiten, können diese nicht mehr
          verändert werden.
        </p>

        <h3>Wo sehe ich den aktuellen Bearbeitungsstand meiner Bestellung?</h3>

        <p>
          Wenn du über einen Zugang zu <a href="/c/account">Mein INTERSPORT</a> verfügst, logge dich
          ein und schon siehst du auf der Mein INTERSPORT-Übersichtsseite den Status zu deiner
          letzten Bestellung. Oder du wechselst in die Übersicht Mein INTERSPORT- Bestellungen, da
          zeigen wir dir die Bestellungen der letzten zwei Jahre inklusive Bestelldetails, wie
          Zahlungsart und Sendungsstatus an.
        </p>

        <h3>Wo kann ich mir ein Kundenkonto anlegen?</h3>

        <p>
          Gehe einfach zur <a href="/c/account/registration">Registrierung</a> und lege dir ein
          Kundenkonto an.
        </p>

        <h3>Wo kann ich meine Daten in Mein INTERSPORT ändern?</h3>

        <p>
          Bitte logge dich hierzu in <a href="/c/account">Mein INTERSPORT</a> ein. Unter "Meine
          Daten" hast du die Möglichkeit deine Daten zu ändern. Für bereits abgeschickte
          Bestellungen gelten die Änderungen nicht.
        </p>

        <h3>Wie kann ich mein Passwort zurücksetzen?</h3>

        <p>
          Solltest du dein Passwort vergessen haben, dann nutze einfach die{" "}
          <a href="/c/account/forgot-password">"Passwort vergessen"-Funktion</a>.
        </p>

        <h3>Kann ich mein Kundenkonto löschen?</h3>

        <p>
          Möchtest du dein Kundenkonto löschen, dann schreibe bitte unserem Kundenservice über das{" "}
          <a href="/c/account/contact?issue=customerAccount">Kontaktformular</a>. Unser Service Team
          übernimmt dies dann für dich.
        </p>

        <h3>Wer bekommt meine Daten?</h3>

        <p>
          Deine Daten werden lediglich zur Auftragsabwicklung genutzt. Hierzu müssen wir zum
          Beispiel unserem Paketdienstleister deine Adressdaten mitteilen. Weitere Details findest
          du unter <a href="/b/datenschutz">Datenschutz</a>.
        </p>

        <div className={css.divider}></div>

        <h2 id="club">Antworten zum INTERSPORT CLUB</h2>

        <h3>Was sind die Vorteile von einer CLUB-Mitgliedschaft?</h3>

        <ul>
          <li>
            Bei der Registrierung eines Kundenkontos registrierst du dich auch gleichzeitig für
            unseren INTERSPORT CLUB.
          </li>
          <li>
            Als neues CLUB-Mitglied schenken wir dir 500 Punkte, dies entspricht einem Guthaben von
            5,- €, die du für deine erste Bestellung einlösen kannst. Beachte dabei den
            Mindesteinkaufswert von 10 €.
          </li>
          <li>
            Oder du löst sie vor Ort beim Händler ein. Zeige dafür einfach deine virtuelle
            CLUB-Kundenkarte an der Kasse vor. Diese findest du unter{" "}
            <a href="/c/account">Mein INTERSPORT</a>.
          </li>
          <li>
            CLUB-Punkte kannst du bei jedem Einkauf sammeln: Pro 1 € Bestellwert sammelst du 1
            CLUB-Punkt.
          </li>
          <li>
            An deinem Geburtstag erhältst du zusätzlich noch einen 10% Rabattgutschein per E-Mail
            oder per Post.
          </li>
          <li>Das Beste: CLUB-Mitglieder zahlen keine Versandkosten.</li>
          <li>
            Sollte dir einmal etwas nicht gefallen, dann hast du außerdem 100 Tage Zeit, uns deinen
            Artikel zu retournieren.
          </li>
        </ul>

        <h3>Wie kann ich meine CLUB-Punkte einlösen?</h3>

        <p>
          Die CLUB-Punkte können ab einem Mindesteinkaufswert von 10 € im Bestellvorgang des
          INTERSPORT Onlineshops oder auch bei deinem Händler vor Ort eingelöst werden. Zeige
          einfach deine digitale CLUB-Karte vor, diese findest du unter{" "}
          <a href="/c/account">Mein INTERSPORT</a>.
        </p>

        <h3>Wo sehe ich meinen aktuellen Punktestand?</h3>
        <p>
          Deinen aktuellen Punktestand kannst du jederzeit in{" "}
          <a href="/c/club">Mein INTERSPORT - Club</a> einsehen. Hier kannst du sehen, was du bisher
          eingelöst hast und wie viele Punkte du bereits mit deinen Einkäufen gesammelt hast. Egal,
          ob du stationär oder online bei uns eingekauft hast.
        </p>

        <h3>
          Wo kann ich die Angebote per Post und E-Mail für CLUB-Mitglieder und von meinem
          Lieblings-Store anpassen?
        </h3>

        <p>
          Unter <a href="/c/club/permissions">Werbeeinstellungen</a> in{" "}
          <a href="/c/account">Mein INTERSPORT</a> kannst du die Angebote per Post und E-Mail
          ändern.
        </p>

        <h3>Kann ich meine Club-Karte auch in meinem Online-Kundenkonto hinterlegen?</h3>

        <p>
          Solltest du schon Club-Mitglied sein, beachte bitte, dass es aktuell nicht möglich ist,
          deine bereits vorhandene Club-Karte in deinem Intersport.de-Kundenkonto zu hinterlegen.
          Solltest du etwas bestellt haben, melde dich bitte bei unserem{" "}
          <a href="/c/account/contact?issue=club">Kundenservice</a>, damit wir dir die Versandkosten
          und Punkte gutschreiben können. Wir arbeiten daran, versprochen.
        </p>

        <div className={css.divider}></div>

        <h2 id="bestellung">Antworten auf Fragen rund um die Lieferung deiner Bestellung</h2>

        <h3>Wie erfolgt die Lieferung meiner Bestellung?</h3>

        <p>
          Wir arbeiten mit den lokalen INTERSPORT-Händlern zusammen, das bedeutet, dass es sein
          kann, dass du deine Artikel in mehreren Paketen erhältst. Grund dafür sind die Entfernung
          und Verfügbarkeit beim jeweiligen Händler - wir wählen immer die für dich beste Variante
          aus und versuchen natürlich dafür zu sorgen, dass du möglichst alle Artikel von einem
          Händler in deiner Nähe zugesendet bekommst.
        </p>

        <h3>Welcher Paketdienstleister beliefert mich?</h3>

        <p>
          Der Händler wählt den Paketdienstleister aus. Dies kann dpd oder DHL sein. Mit welchem
          Paketdienstleister dein Paket versendet wird, erfährst du in der Versandbestätigung. Diese
          enthält auch einen Link auf die jeweilige Sendungsverfolgung, so dass du jederzeit
          nachvollziehen kannst, wo sich dein Paket gerade befindet.
        </p>

        <h3>Wann erfolgt meine Lieferung?</h3>

        <p>
          Nach Eingang deiner Bestellung wird diese so schnell wie möglich vom Händler bearbeitet
          und versendet. Dies dauert in der Regel 2-3 Werktage.
        </p>

        <h3>Wo bleibt meine Lieferung?</h3>

        <p>
          Den aktuellen Status deiner Bestellung kannst du ganz einfach in Mein INTERSPORT -
          Bestellungen nachverfolgen. Du hast als Gast bestellt und schon eine Versandbestätigung
          bekommen? Dann kannst du ganz einfach die Sendungsverfolgung des Paketdienstleisters
          nutzen:
        </p>

        <ul>
          <li>
            <a
              href="https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html"
              target="_blank"
            >
              DHL-Sendungsverfolgung
            </a>
          </li>
          <li>
            <a
              href="https://www.dpd.com/de/de/empfangen/sendungsverfolgung-und-live-tracking/"
              target="_blank"
            >
              dpd-Sendungsverfolgung
            </a>
          </li>
        </ul>

        <h3>Kann ich meine Bestellung per Abstellgenehmigung liefern lassen?</h3>

        <p>
          Du kannst beim jeweiligen Paketdienstleister eine Abstellgenehmigung erteilen. Diese muss
          direkt dort erfolgen. Das Risiko bei Verlust oder Beschädigung geht allerdings auf den
          Vollmachtgeber über.
        </p>

        <h3>Ich habe meine Lieferung nicht erhalten.</h3>

        <p>
          Wenn deine Lieferung den Status "zugestellt” anzeigt, aber du sie dennoch nicht bekommen
          hast, nutze bitte direkt unser{" "}
          <a href="/c/account/contact?issue=delivery">Kontaktformular</a>. Wir prüfen
          schnellstmöglich den Sachverhalt.
        </p>

        <h3>
          Die Sendungsnummer ist nicht aktiv bzw. die Sendungsnummer verändert den Status nicht.
        </h3>

        <p>
          Vereinzelt kann es dazu kommen, dass die Aktualisierung der Tracking-Informationen einige
          Tage in Anspruch nimmt. Sollte sich der Status trotz längerer Wartezeit nicht verändern
          und das Paket nicht bei dir angekommen sein, sende uns bitte eine Nachricht über das{" "}
          <a href="/c/account/contact?issue=delivery">Kontaktformular</a>.
        </p>

        <div className={css.divider}></div>

        <h2 id="ruecksendung">Antworten auf Fragen rund um die Rücksendung</h2>

        <h3>Kann ich einen Artikel auch umtauschen?</h3>

        <p>
          Nein, leider ist ein Umtausch aus technischen Gründen nicht möglich. Du müsstest deinen
          Artikel an uns zurücksenden und in der passenden Größe neu bestellen.
        </p>

        <h3>Bis wann kann ich meine Artikel retournieren?</h3>

        <p>
          Du hast ein Rückgaberecht von 14 Tagen ab Erhalt der Ware. Wenn Du INTERSPORT CLUB-Kunde
          bist, dann hast Du sogar 100 Tage Zeit Deine Bestellung zu retournieren.
        </p>

        <h3>Wo finde ich die Unterlagen für meine Rücksendung?</h3>

        <p>
          Das Retourenlabel liegt deinem Paket bei. Sollte es fehlen, sende uns bitte eine kurze
          Anfrage über das <a href="/c/account/contact?issue=return">Kontaktformular</a> mit den
          Angaben des Artikels, den du retournieren möchtest und der dazugehörigen Auftragsnummer.
          Unser Service Team sendet dir dann ein neues Retourenlabel zu.
        </p>

        <h3>Wie kann ich retournieren und was muss ich dabei beachten?</h3>

        <ul>
          <li>Bitte verpacke deine Retoure sorgfältig; gerne im Versandkarton.</li>
          <li>
            Verwende immer das deinem Paket beiliegende Retourenlabel. Entnehme dem Retourenlabel
            bitte auch den entsprechenden Paketdienstleister, bei dem du das Paket abgeben musst.
          </li>
          <li>
            Solltest du Artikel aus verschiedenen Paketen retournieren wollen, dann dürfen diese
            nicht zusammen in eine Rücksendung gepackt werden.
          </li>
          <li>
            Jeder Artikel muss zurück an den Versender, von dem du den Artikel erhalten hast. Daher
            muss immer das dazugehörige Retourenlabel aus dem Paket verwendet werden.
          </li>
          <li>
            Es kann sein, dass du die Pakete dann auch bei unterschiedlichen Paketdienstleistern
            abgeben musst.
          </li>
          <li>
            dpd-Filialen mit Öffnungszeiten in deiner Nähe findest du{" "}
            <a href="https://www.dpd.com/de/de/pickup-paketshops/paketshop-finden/" target="_blank">
              hier
            </a>
            .
          </li>
          <li>
            DHL-Filialen mit Öffnungszeiten in deiner Nähe findest du{" "}
            <a
              href="https://www.dhl.de/de/privatkunden/pakete-versenden/pakete-abgeben/filiale.html"
              target="_blank"
            >
              hier
            </a>
            .
          </li>
          <li>
            Du kannst auch einen anderen Paketdienstleister nutzen. Die Kosten dafür können wir
            leider nicht übernehmen.
          </li>
        </ul>

        <h3>Kann ich meine Rücksendung auch beim Händler vor Ort abgeben?</h3>
        <p>
          Deine Retoure kann bei jedem INTERSPORT Händler versandfertig abgegeben werden - den Rest
          macht unser Service Team für dich.
        </p>

        <h3>Wo kann ich meine Retoure verfolgen?</h3>

        <p>
          Du möchtest wissen, ob deine Retoure schon bei uns eingegangen ist? Dann nutze hierfür die
          Sendungsverfolgung des Versanddienstleisters oder gehe in{" "}
          <a href="/c/orders">Mein INTERSPORT - Bestellungen</a> und sehe direkt in der
          Bestellhistorie, ob deine Retoure bereits bei uns eingegangen ist. Die Sendungsnummer
          findest du entweder auf dem Retourenlabel oder auf der Abgabequittung.
          DHL-Sendungsverfolgung dpd-Sendungsverfolgung
        </p>

        <h3>Wann erhalte ich mein Geld zurückerstattet?</h3>
        <p>
          Sobald die Retoure beim Versender eintrifft und nach Prüfung gebucht werden kann, bekommst
          du per E-Mail eine Gutschrift. Sofern du bereits Zahlungen zur Bestellung geleistet hast,
          werden wir dir diese schnellstmöglich zurückerstatten. Die Rückzahlung erfolgt stets über
          die beim Einkauf gewählte Zahlart und dauert zwischen 3-5 Werktagen nach Retouren
          Verbuchung.
        </p>

        <h3>Was soll ich machen, wenn meine Rücksendung nicht ankommt?</h3>

        <p>
          Wir bedauern sehr, dass deine Retoure noch nicht bei uns eingegangen ist. Bitte übermittle
          uns die Abgabequittung bzw. den Retourenbeleg zu deiner Retoure, damit wir deinen Fall
          genauer prüfen können. Gerne kannst du hierfür unser{" "}
          <a href="/c/account/contact?issue=complaint">Kontaktformular</a> nutzen.
        </p>

        <h3>Rücksendung über Klarna</h3>
        <p>
          Gerne kannst du dich an Klarna direkt wenden. Gehe einfach unter{" "}
          <a href="https://www.klarna.com/de/kundenservice/" target="_blank">
            Klarna Kundenservice
          </a>{" "}
          oder nutze die Klarna App.
        </p>

        <div className={css.divider}></div>

        <h2 id="reklamation">Antworten auf Fragen zur Reklamation</h2>

        <h3>Was soll ich machen, wenn ich einen mangelhaften Artikel erhalten habe?</h3>

        <p>
          Wir bedauern, dass unser Produkt dir Anlass zur Reklamation gibt. Gerne kannst du uns über
          unser <a href="/c/account/contact?issue=complaint">Kontaktformular</a> die ersten
          Informationen zu deiner Reklamation zukommen lassen.
        </p>

        <h3>Was soll ich machen, wenn ich einen falschen Artikel erhalten habe?</h3>

        <p>
          Wir bedauern, dass du einen falschen Artikel erhalten hast. Bitte sende uns den Artikel
          mit dem beigelegten Retourenlabel zurück und tätige ggf. eine neue Bestellung. Bitte
          beachte, dass deine kostenlose Retoure beim entsprechenden Versanddienstleister aufgegeben
          wird. Dein Retourenlabel hilft dir, welcher Versanddienstleister verwendet werden sollte.
          Vermerke bitte auf dem Rücksendeschein, dass du einen falschen Artikel erhalten hast.
          Einen direkten Umtausch können wir systemtechnisch leider nicht anbieten.
        </p>

        <h3>Was kann ich tun, wenn meine Lieferung beschädigt ist?</h3>
        <p>
          Verweigere die Annahme des Pakets, wenn diese noch nicht erfolgte und reklamiere direkt
          bei dem Paketboten. Sollte dies nicht mehr möglich sein, nutze gerne unser{" "}
          <a href="/c/account/contact?issue=complaint">Kontaktformular</a>. Unser Service Team
          kümmert sich anschließend um den Rest.
        </p>

        <h3>Wie kann ich meine Bestellung widerrufen?</h3>
        <p>
          Deine Widerrufsfrist beträgt 14 Tage ab dem Tag, an dem du oder ein von dir benannter
          Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen hast bzw. hat
          ohne Angabe von Gründen. Um dein Widerrufsrecht auszuüben, musst du uns:
        </p>

        <address>
          INTERSPORT Digital GmbH
          <br />
          Wannenäckerstraße 36
          <br />
          74078 Heilbronn
          <br />
          Deutschland
          <br />
          Tel.: 07131/123 00 90
        </address>
        <p>
          diese mittels einer eindeutigen Erklärung über unser{" "}
          <a href="/c/account/contact?issue=cancellation">Kontaktformular</a> mit folgenden Angaben
          mitteilen:
        </p>

        <p>
          Hiermit widerrufe(n) ich/wir (*)
          <br />
          Kundenname
          <br />
          Bestell-Nr. ( * ) und Bestell-Datum ( * )<br />
          Artikel-Nr. ( * )
        </p>

        <div className={css.divider}></div>

        <h2 id="zahlung">Antworten auf Fragen rund um die Zahlung</h2>

        <h3>Welche Zahlungsmöglichkeiten stehen zur Auswahl?</h3>
        <p>
          Gerne kannst du per PayPal oder Klarna Pay later, Ratenkauf, Sofortbezahlung (Lastschrift
          & Sofortüberweisung) oder nach Bonitätsprüfung per Rechnung - bestellen. Du hast außerdem
          die Möglichkeit, deine Bestellung per Gutschein oder mit INTERSPORT Club-Punkten zu
          bezahlen.
        </p>

        <h3>Kann ich meine Zahlungsfrist verlängern?</h3>
        <p>
          Die Zahlung wird durch unseren Partner Klarna abgewickelt. Melde dich über{" "}
          <a href="https://www.klarna.com/de/" target="_blank">
            Klarna
          </a>{" "}
          oder die Klarna App schnell und einfach an um weitere Informationen zu den Zahlungsfristen
          zu erhalten.
        </p>
      </section>
    </Grid>
  );
}
