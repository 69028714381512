import { Button, Grid, Heading, Text, Alert } from "@idg-web-platform/pulse";
import buttonStyles from "../../styles/buttonStyles.module.css";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect } from "preact/hooks";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";

EmailSentPage.tagName = "control-forgotten-password-email-sent-page";

export function EmailSentPage() {
  useEffect(() => {
    analyticsContentViewed("forgotten_password_email_sent");
  }, []);

  return (
    <>
      <Grid>
        <div className="mono-1-tight">
          <Heading sizeStep={2} text="Du hast Post!" />
          <Text
            sizeStep={1}
            fontStyle="bold"
            removeMargin={true}
            class={margins.pulseMarginBottom2xs}
          >
            Bitte prüfe dein E-Mail-Postfach
          </Text>
          <Text>
            Wenn uns deine E-Mail-Adresse bekannt ist, haben wir dir eine E-Mail geschickt. Klicke
            einfach auf den Link in der E-Mail, um ein neues Passwort festzulegen.
          </Text>
          <Alert
            type="info"
            headline="Du hast keine E-Mail bekommen?"
            description="Bitte überprüfe deinen Spam-Ordner oder die richtige Schreibweise deiner E-Mail-Adresse."
            class={margins.pulseMarginBottomM}
            showCloseButton={false}
          />
          <Button
            class={buttonStyles.buttonFullWidth}
            level="primary"
            text="ZURÜCK ZUR ANMELDUNG"
            href="/c/account/login"
          />
        </div>
      </Grid>
    </>
  );
}
