export const ValidatedAddressFormFields = {
  title: "title",
  firstName: "firstName",
  lastName: "lastName",
  streetName: "streetName",
  streetNr: "streetNr",
  zipCode: "zipCode",
  additionalAddressInformation: "additionalAddressInformation",
  city: "city",
  addressLines: "addressLines",
  countryCode: "countryCode",
  birthday: "birthday",
  phoneNumber: "phoneNumber",
} as const;

export type ValidatedAddressFormFields =
  (typeof ValidatedAddressFormFields)[keyof typeof ValidatedAddressFormFields];

export const ValidatedAddressFormLabels: {
  [key in ValidatedAddressFormFields]: string;
} = {
  [ValidatedAddressFormFields.title]: "Anrede",
  [ValidatedAddressFormFields.firstName]: "Vorname",
  [ValidatedAddressFormFields.lastName]: "Nachname",
  [ValidatedAddressFormFields.streetName]: "Straße",
  [ValidatedAddressFormFields.streetNr]: "Hausnr.",
  [ValidatedAddressFormFields.zipCode]: "PLZ",
  [ValidatedAddressFormFields.additionalAddressInformation]: "Adresszusatz",
  [ValidatedAddressFormFields.addressLines]: "Adresse",
  [ValidatedAddressFormFields.city]: "Ort",
  [ValidatedAddressFormFields.countryCode]: "Land",
  [ValidatedAddressFormFields.birthday]: "Geburtstag",
  [ValidatedAddressFormFields.phoneNumber]: "Telefonnummer",
};

export const ValidatedAddressFormErrorMessages: {
  [key in ValidatedAddressFormFields]?: string;
} = {
  [ValidatedAddressFormFields.title]: "Bitte wähle eine Anrede aus",
  [ValidatedAddressFormFields.firstName]: "Bitte gib deinen Vornamen ein",
  [ValidatedAddressFormFields.lastName]: "Bitte gib deinen Nachnamen ein",
  [ValidatedAddressFormFields.streetName]: "Bitte gib deinen Straßennamen ein",
  [ValidatedAddressFormFields.zipCode]: "Postleitzahl ungültig",
  [ValidatedAddressFormFields.addressLines]: "Bitte gib deine Adresse ein",
  [ValidatedAddressFormFields.city]: "Bitte gib deinen Ort ein",
  [ValidatedAddressFormFields.countryCode]: "Bitte wähle dein Land aus",
  [ValidatedAddressFormFields.birthday]:
    "Bitte gib einen gültigen Geburtstag ein (mind. 16 Jahre alt)",
  [ValidatedAddressFormFields.phoneNumber]:
    "Bitte gib eine gültige Telefonnummer ein (z.B. +491234567890)",
};

export interface ValidatedAddressFormProps {
  id: string;
  countries: [string, string][];
  data?: ValidatedAddressFormPropsData;
  googleApiKey: string;
}

export interface ValidatedAddressFormRef {
  getFormState: () => ValidatedAddressFormState;
  validateForm: () => Promise<boolean>;
}

export interface ValidatedAddressFormPropsData {
  [ValidatedAddressFormFields.title]?: "" | "m" | "f" | "d";
  [ValidatedAddressFormFields.firstName]?: string;
  [ValidatedAddressFormFields.lastName]?: string;
  [ValidatedAddressFormFields.additionalAddressInformation]?: string;
  [ValidatedAddressFormFields.addressLines]?: string;
  [ValidatedAddressFormFields.streetName]?: string;
  [ValidatedAddressFormFields.streetNr]?: string;
  [ValidatedAddressFormFields.zipCode]?: string;
  [ValidatedAddressFormFields.city]?: string;
  [ValidatedAddressFormFields.countryCode]?: string; // ISO 3166-1 alpha-2, e.g. DE, FR, GB
  [ValidatedAddressFormFields.birthday]?: string; // ISO 8601: YYYY-MM-DD
  [ValidatedAddressFormFields.phoneNumber]?: string;
}

export type ValidatedAddressFormState = Record<
  ValidatedAddressFormFields,
  { value: string; isValid: boolean | undefined }
>;

export const initializeFormState = (
  initialData?: Partial<ValidatedAddressFormPropsData>,
): ValidatedAddressFormState => ({
  [ValidatedAddressFormFields.title]: {
    value: initialData?.title || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.firstName]: {
    value: initialData?.firstName || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.lastName]: {
    value: initialData?.lastName || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.streetName]: {
    value: initialData?.streetName || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.streetNr]: {
    value: initialData?.streetNr || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.zipCode]: {
    value: initialData?.zipCode || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.additionalAddressInformation]: {
    value: initialData?.additionalAddressInformation || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.addressLines]: {
    value: initialData?.addressLines || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.city]: {
    value: initialData?.city || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.countryCode]: {
    value: initialData?.countryCode || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.birthday]: {
    value: initialData?.birthday || "",
    isValid: undefined,
  },
  [ValidatedAddressFormFields.phoneNumber]: {
    value: initialData?.phoneNumber || "",
    isValid: undefined,
  },
});
