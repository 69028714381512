const locale = "de-DE";

const amountFormatOptions: Intl.NumberFormatOptions = {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export function formatAmount(amount: number): string {
  return amount.toLocaleString(locale, amountFormatOptions);
}

const formatter = new Intl.DateTimeFormat(locale, {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

export function formatDate(date: Date | string): string {
  return formatter.format(new Date(date)); // dates are strings in JSON, so preactement passes a string
}
