import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "@aws-amplify/core";
import * as Auth from "@aws-amplify/auth";
import analytics from "@idg-data-analytics-platform/analytics";

export const ControlUserPoolId: string | undefined = undefined;
export let ControlUserPoolClientId: string | undefined = undefined;

/**
 * Pre-condition for Auth.signUp and Auth.signIn.
 * Emits a 'cognito-configured' event when the configuration is done, so that independent components can wait for it.
 */
export function configureCognito(userPoolId: string, userPoolClientId: string) {
  console.debug(
    `Configure Amplify/Cognito for user pool ${userPoolId} and client ${userPoolClientId} on ${window.location.hostname}.`,
  );
  ControlUserPoolClientId = userPoolId;
  ControlUserPoolClientId = userPoolClientId;

  Amplify.configure(
    {
      Auth: { Cognito: { userPoolId, userPoolClientId } },
    },
    { ssr: false }, // only in the browser
  );
  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
      path: "/",
      expires: 60,
      secure: window.location.protocol === "https:",
      sameSite: "lax",
    }),
  );
  window.dispatchEvent(new CustomEvent("cognito-configured"));
}

/**
 * Sign in with E-mail address and password.
 * In case of success, the auth cookie is store and the user is redirected to the given URL.
 */
export async function login(email: string, password: string, redirect: string): Promise<void> {
  await Auth.signIn({
    username: email,
    password: password,
    options: {
      authFlowType: "USER_PASSWORD_AUTH",
    },
  });

  analytics({
    event: "generic_login_successful",
    team: "control",
  });

  await storeAuthCookie();
  document.location.href = redirect;
}

/**
 * After sign-up or sign-in, the control-authorization-source is set to the Cognito idToken cookie name.
 */
async function storeAuthCookie() {
  const result = await eventualCognitoConfig();
  const userPoolClientId = result[1];

  // parse cookies into a map
  const cookies = new Map<string, string>();
  document.cookie.split(";").map((c) => {
    const [key, value] = c.trim().split("=");
    cookies.set(decodeURIComponent(key.trim()), decodeURIComponent(value));
  });

  const lastAuth = cookies.get(`CognitoIdentityServiceProvider.${userPoolClientId}.LastAuthUser`);

  const idTokenCookieName = lastAuth
    ? `CognitoIdentityServiceProvider.${userPoolClientId}.${lastAuth}.idToken`
    : `CognitoIdentityServiceProvider.${userPoolClientId}.idToken`;

  const expires = new Date();
  expires.setDate(expires.getDate() + 60);
  const idTokenCookieNameEncoded = encodeURIComponent(
    // double encoding: usernames are email addresses which leads to URL encoded cookie names with due to the '@'.
    // for some reason '+' is not encoded by Cognito but by `encodeURIComponent`, so we may not encode '+' twice.
    encodeURIComponent(idTokenCookieName).replace("%2B", "+"),
  );

  const isSecure = window.location.protocol === "https:";
  const secureString = isSecure ? " secure;" : "";
  document.cookie = `control-authorization-source=${idTokenCookieNameEncoded}; expires=${expires.toUTCString()}; path=/; sameSite=lax; ${secureString}`;
}

/**
 * Makes sure that given continuation is executed once after Cognito has been configured.
 */
export function withCognitoConfig(
  continuation: (userPoolId: string, userPoolClientId: string) => void,
) {
  if (Amplify.getConfig().Auth !== undefined) {
    continuation(ControlUserPoolId!, ControlUserPoolClientId!);
  } else {
    window.addEventListener(
      "cognito-configured",
      () => {
        continuation(ControlUserPoolId!, ControlUserPoolClientId!);
      },
      false,
    );
  }
}

export function eventualCognitoConfig(): Promise<[userPoolId: string, userPoolClientId: string]> {
  return new Promise<[userPoolId: string, userPoolClientId: string]>((resolve) => {
    withCognitoConfig((userPoolId, userPoolClientId) => resolve([userPoolId, userPoolClientId]));
  });
}

export async function forceRefreshToken(): Promise<void> {
  await Auth.fetchAuthSession({ forceRefresh: true });
}
