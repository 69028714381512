import { Title } from "../../interfaces/title.ts";
import { CompleteAccountDTO } from "../../interfaces/completeAccountDTO.ts";
import { TitleSelectOptions } from "../../constants/titleSelectOptions.ts";
import { formatDate } from "../../utils/formats.ts";
import { NavigationWrapper } from "../../components/navigation/navigationWrapper.tsx";
import { Button, Heading, IconClose, IconCreate, IconInfo, Text } from "@idg-web-platform/pulse";
import css from "./myAccount.module.css";
import { useEffect, useRef, useState } from "preact/hooks";
import { ValidatedAddressForm } from "../../components/validatedAddressForm/validatedAddressForm.tsx";
import {
  ValidatedAddressFormFields,
  ValidatedAddressFormRef,
} from "../../interfaces/validatedAddressForm.ts";
import { createInvoiceAddress } from "../../functions/createInvoiceAddress.ts";
import { AccountData } from "../../interfaces/accountData.ts";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";
import { ContactFormIssues } from "../contact/contactForm.ts";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useManagedAlert } from "../../components/managedAlert/useManagedAlert.ts";
import { ManagedAlert } from "../../components/managedAlert/managedAlert.tsx";

DataPage.tagName = "control-my-data-page";

export function DataPage(dataPageProps: DataPageProps) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { alert, showAlert, closeAlert } = useManagedAlert();
  const [personalData, setPersonalData] = useState(dataPageProps.completeAccountDTO.personalData);
  const [address, setAddress] = useState(dataPageProps.completeAccountDTO.address);
  const isGermany = (countryCode: string) => {
    return countryCode === "DE";
  };
  const [isGermanAddress, setIsGermanAddress] = useState(
    isGermany(dataPageProps.completeAccountDTO.address.countryCode),
  );
  const formatCustomerNumber = (customerNumber: string) => {
    // for not generated customer numbers
    if (customerNumber.length !== 10) {
      return customerNumber;
    }

    // format 2-4-4 like 12 3456 7890
    return `${customerNumber.slice(0, 2)} ${customerNumber.slice(2, 6)} ${customerNumber.slice(6)}`;
  };

  const title = personalData?.title as Title;
  const validatedAddressFormRef = useRef<ValidatedAddressFormRef>(null);
  const deleteAccountIssueKey: keyof typeof ContactFormIssues = "accountDeletion";

  useEffect(() => {
    analyticsContentViewed("my_data");
  }, []);

  const onFormSubmit = async (e: Event) => {
    e.preventDefault();

    const validationResult = await validatedAddressFormRef.current!.validateForm();

    if (validationResult) {
      const formState = validatedAddressFormRef.current!.getFormState();
      const invoiceAddress = createInvoiceAddress(formState);
      const accountData: AccountData = {
        invoiceAddress,
        birthday: formState[ValidatedAddressFormFields.birthday].value || undefined,
      };

      const response = await fetch("/api/c/account/my-data", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(accountData),
      });

      if (response.ok) {
        setIsGermanAddress(isGermany(formState[ValidatedAddressFormFields.countryCode].value));
        invoiceAddress.countryName = dataPageProps.countries.find(
          (c) => c[0] === invoiceAddress.countryCode,
        )![1];
        setAddress({
          ...address,
          ...invoiceAddress,
        });
        setPersonalData({
          ...personalData,
          birthday: accountData.birthday,
          phoneNumber: formState[ValidatedAddressFormFields.phoneNumber].value,
          title: formState[ValidatedAddressFormFields.title].value as Title,
          firstName: formState[ValidatedAddressFormFields.firstName].value,
          lastName: formState[ValidatedAddressFormFields.lastName].value,
        });
        setIsGermanAddress(isGermany(invoiceAddress.countryCode));
        setIsEditMode(false);
      } else {
        showAlert({
          headline: "Fehler beim Speichern",
          description: "Bitte versuche es später noch ein mal",
          severity: "error",
        });
      }
    }
  };

  return (
    <>
      <NavigationWrapper currentPath="/c/account/my-data">
        <Heading sizeStep={2} text="Meine Daten" />
        <p>Hier kannst du deine persönlichen Daten einsehen und anpassen.</p>
        <p>
          <strong>Kundennummer:</strong>{" "}
          <span data-dd-privacy="mask">
            {formatCustomerNumber(dataPageProps.completeAccountDTO.customerNumber)}
          </span>
        </p>
        <p className={margins.pulseMarginBottomL}>
          <strong>E-Mail:</strong>{" "}
          <span data-dd-privacy="mask">{dataPageProps.completeAccountDTO.email}</span>
        </p>

        <div className={css.card}>
          <div className={css.cardHeader}>
            <strong className={css.cardHeading}>Persönliche Daten</strong>
            <div className={css.cardEditButton}>
              <Button
                text="Persönliche Daten bearbeiten"
                iconOnly
                iconComponent={
                  !isEditMode ? (
                    <IconCreate data-testid="con-personal-data-edit" />
                  ) : (
                    <IconClose data-testid="con-personal-data-end-edit" />
                  )
                }
                data-testid="control-view-edit-toggle-button"
                onClick={() => setIsEditMode(!isEditMode)}
              />
            </div>
          </div>

          {!isEditMode && (
            <>
              <div data-dd-privacy="mask">
                {TitleSelectOptions.find((o) => o.value === title)?.label}
              </div>

              <div data-dd-privacy="mask">{`${personalData.firstName} ${personalData.lastName}`}</div>

              {isGermanAddress && (
                <>
                  <div data-dd-privacy="mask">{`${address.streetName} ${address.streetNr}`}</div>
                  {address.additionalAddressInformation && (
                    <div data-dd-privacy="mask">{address.additionalAddressInformation}</div>
                  )}
                  <div data-dd-privacy="mask">{`${address.zipCode} ${address.city}`}</div>
                </>
              )}
              {!isGermanAddress && (
                <>
                  {address.formattedAddress?.map((line) => (
                    <div data-dd-privacy="mask">{line}</div>
                  ))}
                  <div data-dd-privacy="mask">{`${address.countryName}`}</div>
                </>
              )}

              {personalData.birthday && (
                <div data-dd-privacy="mask">Geburtstag: {formatDate(personalData.birthday)}</div>
              )}

              {personalData.phoneNumber && (
                <div data-dd-privacy="mask">Telefon: {personalData.phoneNumber}</div>
              )}
            </>
          )}
          {isEditMode && (
            <form onSubmit={onFormSubmit} noValidate data-dd-privacy="mask">
              <ValidatedAddressForm
                id="invoice"
                ref={validatedAddressFormRef}
                countries={dataPageProps.countries}
                googleApiKey={dataPageProps.googleApiKey}
                data={{
                  title: title,
                  firstName: personalData.firstName,
                  lastName: personalData.lastName,
                  additionalAddressInformation: address.additionalAddressInformation,
                  streetName: address.streetName,
                  streetNr: address.streetNr,
                  zipCode: address.zipCode,
                  city: address.city,
                  countryCode: address.countryCode,
                  phoneNumber: personalData.phoneNumber,
                  birthday: personalData.birthday,
                  addressLines: address.formattedAddress?.join("\n"),
                }}
              />

              <ManagedAlert alert={alert} onClose={closeAlert} />

              <div className={css.cardButtons}>
                <Button
                  text={"Abbrechen"}
                  level="secondary"
                  onClick={() => {
                    setIsEditMode(false);
                  }}
                />
                <Button text={"Speichern"} type={"submit"} level="primary" />
              </div>
            </form>
          )}
        </div>

        <div className={css.passwordHint}>
          <IconInfo /> Du möchtest dein Passwort ändern? Melde dich ab und nutze die "Passwort
          vergessen"-Funktion.
        </div>

        <Text
          fontStyle="bold"
          sizeStep={0}
          removeMargin={true}
          class={margins.pulseMarginBottom2xs}
        >
          Kundenkonto löschen
        </Text>
        <Text sizeStep={0}>
          Du kannst dein Kundenkonto durch unseren{" "}
          <a href={`/c/account/contact?issue=${deleteAccountIssueKey}`}>Kundenservice</a> löschen
          lassen. Bitte beachte, dass deine CLUB-Mitgliedschaft dadurch ebenfalls beendet wird.
        </Text>
      </NavigationWrapper>
    </>
  );
}

export interface DataPageProps {
  completeAccountDTO: CompleteAccountDTO;
  googleApiKey: string;
  countries: [string, string][];
}
