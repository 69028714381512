import analytics from "@idg-data-analytics-platform/analytics";

export function analyticsContentViewed(level1: string, level2?: string) {
  analytics({
    event: "content_viewed",
    team: "control",
    properties: {
      type: "account",
      level1,
      ...(level2 && { level2 }),
    },
  });
}
