export const validateDateOfBirth = (value: string) => {
  const date = new Date(value);
  const today = new Date();
  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - 16);

  return date < minDate;
};

export const validatePhoneNumber = (value: string) => {
  // allowed example: +491234567890
  return /^[+\d]+$/.test(value);
};

export const validateZipCode = (value: string) => {
  return /^\d{5}$/.test(value);
};

export const validateNotEmpty = (value: string) => {
  return value.trim().length > 0;
};

export const validateEmail = (value: string) => {
  return /^(?!.*\.\.)[a-zA-Z0-9][a-zA-Z0-9._%+-]*[a-zA-Z0-9]@[a-zA-Z0-9.-]+[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(
    value,
  );
};
