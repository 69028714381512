declare global {
  interface Window {
    ssiForURL: { [key: string]: string };
  }
}

/**
 * Embeds a fragment via SSI on the server-side.
 * Prevents the fragment from being re-rendered as the unresolved SSI on the client-side.
 *
 * It is necessary in each situation where an SSI is included in a component that is re-rendered on the client-side,
 * that is, having kind of interactivity.
 */
export default function SSI({ url }: { url: string }) {
  try {
    // Rendering on the client-side, we have already resolved the SSI stored in `ssiForURL` (see below).

    const ssiRerender = window.ssiForURL[url];
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: ssiRerender }}></div>
      </>
    );
  } catch {
    // Rendering on the server-side, we need to include the SSI via the `<!--#include virtual="${url}" -->` comment.
    // We also render a tiny script that stores the SSI in `ssiForURL` as soon as the browser reads it to use it
    // during re-rendering.
    return (
      <>
        <div
          data-ssi={url}
          dangerouslySetInnerHTML={{
            __html: `<!--#include virtual="${url}" -->`,
          }}
        ></div>

        <script
          dangerouslySetInnerHTML={{
            __html: `
                try {
                    const ssiStore = document.querySelector("[data-ssi='${url}']").innerHTML;
                    if (ssiStore.startsWith('<!--') && ssiStore.endsWith('#include virtual="${url}" -->')) {
                        console.error("Failed to include ${url} via SSI: " + ssiStore);
                    } else {
                        if (!window.ssiForURL) {
                            window.ssiForURL = {};
                        }
                        window.ssiForURL['${url}'] = ssiStore;
                    }
                } catch (e) {
                    console.error("Failed to store SSI content for ${url}: ", e);
                }
        `,
          }}
        />
      </>
    );
  }
}
