import { addressvalidation_v1 } from "googleapis";

export enum ValidationResult {
  CONFIRMED,
  CORRECTED,
  FAILED,
}

export type AddressData = {
  additionalAddressInformation: string;
  street: string;
  streetNumber?: string;
  zipCode: string;
  city: string;
};

export type EUAddress = {
  lines: string[];
  countryCode: string;
};

export type ValidatedAddress = {
  validation: ValidationResult;
  addressData: AddressData;
  countryCode: string;
  formattedAddress: string[];
};

async function requestValidation(
  googleApiKey: string,
  request: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressRequest,
): Promise<addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressResponse> {
  const httpResponse = await fetch(
    `https://addressvalidation.googleapis.com/v1:validateAddress?key=${googleApiKey}`,
    {
      method: "POST",
      body: JSON.stringify(request),
    },
  );

  if (!httpResponse.ok) {
    throw new Error(`${httpResponse.status} - ${httpResponse.statusText}`);
  }

  const validateAddressResponse: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressResponse =
    await httpResponse.json();

  const result = validateAddressResponse?.result;

  if (!result) {
    throw new Error("Google API did not return a result");
  }

  if (!result.verdict) {
    throw new Error("Google API did not return an address verdict");
  }

  return validateAddressResponse;
}

export async function validateAddressWithGoogle(
  googleApiKey: string,
  request: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressRequest,
): Promise<addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressResponse> {
  return await requestValidation(googleApiKey, request);
}

function extractComponent(
  components: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1AddressComponent[],
  componentType: string,
): string {
  return (
    components.find((component) => component.componentType === componentType)?.componentName
      ?.text || ""
  );
}

function normalizeString(str: string): string {
  return str.trim().toLowerCase();
}

function checkForChangedComponents(
  inputAddress: AddressData | EUAddress,
  outputComponents: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1AddressComponent[],
): boolean {
  if ("lines" in inputAddress) {
    // for EUAddress we don't check for changes in the address lines
    return false;
  }

  // Extract components from output
  const outputStreet = normalizeString(extractComponent(outputComponents, "route"));
  const outputStreetNumber = normalizeString(extractComponent(outputComponents, "street_number"));
  const outputZipCode = normalizeString(extractComponent(outputComponents, "postal_code"));
  const outputCity = normalizeString(extractComponent(outputComponents, "locality"));

  // inputAddress is AddressData
  const inputStreet = normalizeString(inputAddress.street);
  const inputStreetNumber = normalizeString(inputAddress.streetNumber || "");
  const inputZipCode = normalizeString(inputAddress.zipCode);
  const inputCity = normalizeString(inputAddress.city);

  const streetChanged = inputStreet !== outputStreet;
  const streetNumberChanged = inputStreetNumber !== outputStreetNumber;
  const zipCodeChanged = inputZipCode !== outputZipCode;
  const cityChanged = inputCity !== outputCity;

  return streetChanged || streetNumberChanged || zipCodeChanged || cityChanged;
}

function extractResult(
  response: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressResponse,
  inputAddressComponents: AddressData | EUAddress,
): ValidationResult {
  const components = response.result!.address!.addressComponents!;

  if (hasSuspiciousComponents(components)) {
    console.info(
      "control.accounts: Google API returned suspicious components",
      inputAddressComponents,
      components,
    );
    return ValidationResult.FAILED;
  }

  const hasChangedComponents = checkForChangedComponents(
    inputAddressComponents,
    response.result!.address!.addressComponents!,
  );

  if (hasChangedComponents) {
    return ValidationResult.CORRECTED;
  } else {
    if (isPostalCodeCorrect(components)) {
      return ValidationResult.CONFIRMED;
    } else {
      return ValidationResult.FAILED;
    }
  }
}

function isPostalCodeCorrect(
  components: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1AddressComponent[],
): boolean {
  const postalCodeComponent = components.find(
    (component) => component.componentType === "postal_code",
  );

  return postalCodeComponent !== undefined && postalCodeComponent.confirmationLevel === "CONFIRMED";
}

function hasSuspiciousComponents(
  components: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1AddressComponent[],
): boolean {
  return components.some(
    (component) => component.confirmationLevel === "UNCONFIRMED_AND_SUSPICIOUS",
  );
}

export function mergeGermanAddress(
  addressData: AddressData,
  response: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressResponse,
): ValidatedAddress {
  const result = extractResult(response, addressData);

  const formattedAddress =
    response.result!.address!.formattedAddress?.split(", ").slice(0, -1) || []; // Remove country with slice

  if (result === ValidationResult.CONFIRMED) {
    return {
      validation: result,
      addressData,
      countryCode: "DE",
      formattedAddress,
    };
  } else {
    const components = response.result!.address!.addressComponents!;
    const correctedAddress: AddressData = {
      additionalAddressInformation: addressData.additionalAddressInformation,
      street: extractComponent(components, "route") || addressData.street,
      streetNumber: extractComponent(components, "street_number") || addressData.streetNumber,
      zipCode: extractComponent(components, "postal_code") || addressData.zipCode,
      city: extractComponent(components, "locality") || addressData.city,
    };
    return {
      validation: result,
      addressData: correctedAddress,
      countryCode: "DE",
      formattedAddress,
    };
  }
}

export async function validateGermanAddress(
  googleApiKey: string,
  address: AddressData,
): Promise<ValidatedAddress> {
  const googleValidationRequest: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressRequest =
    {
      address: {
        addressLines: [`${address.street} ${address.streetNumber || ""}`.trimEnd()],
        postalCode: address.zipCode,
        locality: address.city,
        regionCode: "DE",
      },
    };

  const response = await validateAddressWithGoogle(googleApiKey, googleValidationRequest);

  return mergeGermanAddress(address, response);
}

export function mergeEUAddress(
  inputAddress: EUAddress,
  response: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressResponse,
): ValidatedAddress {
  const result = extractResult(response, inputAddress);

  const address = response.result!.address!;
  const components = address.addressComponents!;

  // Remove the line with the street from the address lines
  const route = extractComponent(components, "route");
  let addressLines = address.postalAddress!.addressLines || [];
  addressLines = addressLines.filter((line) => !route || !line.includes(route));

  const formattedAddress = address.formattedAddress!.split(", ").slice(0, -1) || []; // Remove country with slice

  const correctedAddress: AddressData = {
    additionalAddressInformation: addressLines.join(", "),
    street: route || "",
    streetNumber: extractComponent(components, "street_number"),
    zipCode: extractComponent(components, "postal_code") || "",
    city: extractComponent(components, "locality") || "",
  };

  return {
    validation: result,
    addressData: correctedAddress,
    countryCode: inputAddress.countryCode,
    formattedAddress,
  };
}

export async function validateEUAddress(
  googleApiKey: string,
  address: EUAddress,
): Promise<ValidatedAddress> {
  const googleValidationRequest: addressvalidation_v1.Schema$GoogleMapsAddressvalidationV1ValidateAddressRequest =
    {
      address: {
        addressLines: address.lines,
        regionCode: address.countryCode,
      },
    };

  const response = await validateAddressWithGoogle(googleApiKey, googleValidationRequest);

  return mergeEUAddress(address, response);
}
