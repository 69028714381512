import {
  Button,
  Heading,
  IconArrowBigRight,
  IconLink,
  IconLogout,
  Text,
} from "@idg-web-platform/pulse";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect, useState } from "preact/hooks";
import css from "./myAccount.module.css";
import { TitleSelectOptions } from "../../constants/titleSelectOptions.ts";
import { Title } from "../../interfaces/title.ts";
import SSI from "../../ssi.tsx";
import { formatDate } from "../../utils/formats.ts";
import { NavigationWrapper } from "../../components/navigation/navigationWrapper.tsx";
import { InvoiceAddress } from "../../interfaces/invoiceAddress.ts";
import margin from "@idg-web-platform/pulse/cssModules/margins.module.css";
import * as Auth from "@aws-amplify/auth";
import { ManagedAlert } from "../../components/managedAlert/managedAlert.tsx";
import { useManagedAlert } from "../../components/managedAlert/useManagedAlert.ts";

MyAccount.tagName = "control-my-account";

export function MyAccount({
  personalData,
  invoiceAddress,
  passwordSuccessfullyReset,
}: MyAccountProps) {
  const isGermanAddress = invoiceAddress.countryCode === "DE";
  const [isSigningOut, setIsSigningOut] = useState(false);
  const { alert, showAlert, closeAlert } = useManagedAlert();

  const logout = () => {
    setIsSigningOut(true);
    Auth.signOut()
      .then(() => {
        document.location.href = "/c/account/logout";
      })
      .catch((err: Error) => {
        console.error(err);
        setIsSigningOut(false);
      });
  };

  useEffect(() => {
    analyticsContentViewed("my_account");

    if (passwordSuccessfullyReset) {
      showAlert({
        severity: "success",
        headline: "Passwort erfolgreich geändert",
        description: "",
      });
    }
  }, [passwordSuccessfullyReset, showAlert]);

  return (
    <NavigationWrapper currentPath="/c/account">
      <div className={css.myAccount}>
        <ManagedAlert alert={alert} onClose={closeAlert} />

        <div>
          <Heading
            data-dd-privacy="mask"
            text={`Hallo ${personalData.firstName}`}
            removeMargin={true}
            element={"h2"}
            sizeStep={2}
            class={css.hello}
          />
          <Text sizeStep={0}>willkommen in deinem Kundenkonto!</Text>
          <Text sizeStep={0}>
            Hier findest du alles rund um deine Bestellungen, CLUB-Punkte und deine persönlichen
            Daten.
          </Text>
        </div>

        <div className={css.section}>
          <div className={css.sectionHeadlineFull}>
            <Heading removeMargin={true} element={"h2"} sizeStep={2} class={css.heading}>
              Club
            </Heading>
            <IconLink
              text="Club Übersicht"
              href={"/c/club"}
              iconComponent={<IconArrowBigRight />}
              iconPosition="right"
            />
          </div>
          <SSI
            url={`/fragments/c/club/summary?firstName=${encodeURIComponent(
              personalData.firstName,
            )}&lastName=${encodeURIComponent(personalData.lastName)}`}
          />
        </div>

        <div className={margin.pulseMarginBottomM}>
          <SSI url={"/fragments/c/orders/last"} />
        </div>

        <div className={css.desktopHalfCard}>
          <div className={css.section}>
            <div>
              <Heading text="Meine Daten" removeMargin={true} element={"h2"} sizeStep={2} />
              <IconLink
                text="Meine Daten Übersicht"
                href={"/c/account/my-data"}
                iconComponent={<IconArrowBigRight />}
                iconPosition="right"
              />
            </div>

            <div className={css.card} data-dd-privacy="mask">
              <strong>Persönliche Daten</strong>
              <div>{TitleSelectOptions.find((o) => o.value === personalData.title)?.label}</div>
              <div>{`${personalData.firstName} ${personalData.lastName}`}</div>

              {isGermanAddress && (
                <>
                  <div>{`${invoiceAddress.streetName} ${invoiceAddress.streetNr}`}</div>
                  {invoiceAddress.additionalAddressInformation && (
                    <div>{invoiceAddress.additionalAddressInformation}</div>
                  )}
                  <div>{`${invoiceAddress.zipCode} ${invoiceAddress.city}`}</div>
                </>
              )}
              {!isGermanAddress && (
                <>
                  {invoiceAddress.formattedAddress?.map((line) => <div>{line}</div>)}
                  <div>{`${invoiceAddress.countryName}`}</div>
                </>
              )}

              {personalData.birthday && <div>Geburtstag: {formatDate(personalData.birthday)}</div>}

              {personalData.phoneNumber && <div>Telefon: {personalData.phoneNumber}</div>}
            </div>
          </div>

          <div className={css.section}>
            <div>
              <Heading text="Werbeeinstellungen" removeMargin={true} element={"h2"} sizeStep={2} />
              <IconLink
                text="Werbeeinstellungen Übersicht"
                href={"/c/club/permissions"}
                iconComponent={<IconArrowBigRight />}
                iconPosition="right"
              />
            </div>
            <div className={css.card}>
              <div>Behalte den Überblick, wir halten dich auf dem Laufenden.</div>
            </div>
          </div>
        </div>
        <div className={css.logoutButton}>
          <Button
            iconPosition={"right"}
            text="Abmelden"
            type="button"
            level="secondary"
            iconComponent={<IconLogout />}
            onClick={logout}
            isLoading={isSigningOut}
          />
        </div>
      </div>
    </NavigationWrapper>
  );
}

interface MyAccountProps {
  personalData: PersonalData;
  invoiceAddress: InvoiceAddress;
  passwordSuccessfullyReset?: boolean;
}

export interface PersonalData {
  customerNumber: string;
  email: string;
  title: Title;
  firstName: string;
  lastName: string;
  birthday?: string;
  phoneNumber?: string;
}
