import { NavigationMobile } from "./navigationMobile.tsx";
import { NavigationDesktop } from "./navigationDesktop.tsx";
import { useState } from "preact/hooks";
import * as Auth from "@aws-amplify/auth";

Navigation.tagName = "control-account-navigation";

interface NavigationProps {
  currentPath?: string;
}

export interface NavItem {
  name: string;
  path: string;
}

export type NavItems = Array<NavItem>;

export const navItems: NavItems = [
  { name: "Meine Bestellungen", path: "/c/orders" },
  { name: "CLUB", path: "/c/club" },
  { name: "Meine Daten", path: "/c/account/my-data" },
  { name: "Werbeeinstellungen", path: "/c/club/permissions" },
];

export function Navigation({ currentPath }: NavigationProps) {
  const [isSigningOut, setIsSigningOut] = useState(false);

  function logout() {
    setIsSigningOut(true);
    Auth.signOut()
      .then(() => {
        window.open("/c/account/logout", "_self");
      })
      .catch((err: Error) => {
        console.error(err);
        setIsSigningOut(false);
      });
  }

  return (
    <>
      <NavigationMobile currentPath={currentPath} logoutHandler={logout} navItems={navItems} />
      <NavigationDesktop
        currentPath={currentPath}
        logoutHandler={logout}
        isSigningOut={isSigningOut}
        navItems={navItems}
      />
    </>
  );
}
