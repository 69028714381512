import { Grid } from "@idg-web-platform/pulse";
import { ComponentChildren } from "preact";
import { Navigation } from "./navigation.tsx";

NavigationWrapper.tagName = "control-account-navigation-wrapper";

interface NavigationWrapperProps {
  currentPath?: string;
  children: ComponentChildren;
}

export function NavigationWrapper(props: NavigationWrapperProps) {
  return (
    <Grid>
      <div className="double-1_3-left">
        <Navigation currentPath={props.currentPath} />
      </div>
      <div className="double-1_3-right">{props.children}</div>
    </Grid>
  );
}
