import * as Auth from "@aws-amplify/auth";
import { Alert, Button, Grid, Heading, Text, TextLink } from "@idg-web-platform/pulse";
import buttonStyles from "../../styles/buttonStyles.module.css";
import { NewPasswordInput } from "../../components/newPasswordInput/newPasswordInput.tsx";
import { eventualCognitoConfig } from "../../config/cognito.ts";
import colors from "../../styles/colors.module.css";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect, useState } from "preact/hooks";
import { validateEmail } from "../../functions/validateFormFields.ts";
import { ManagedAlert } from "../../components/managedAlert/managedAlert.tsx";
import { useManagedAlert } from "../../components/managedAlert/useManagedAlert.ts";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";

SetPasswordPage.tagName = "control-set-forgotten-password-page";

export function SetPasswordPage(setForgottenPasswordPageProps: SetForgottenPasswordPageProps) {
  return <SetForgottenPasswordPageComponent {...setForgottenPasswordPageProps} />;
}

/**
 * Codes sent by Cognito are valid for an hour.
 */
export function isFreshCode(codeSent: number) {
  const expiredBefore = new Date().getTime() - 1000 * 3600; // one hour
  return codeSent > expiredBefore;
}

const expirationErrorMessage =
  "Der Link ist abgelaufen, starte den Passwort vergessen-Prozess neu und beachte bitte, dass der Link nur eine Stunde gültig ist.";

function SetForgottenPasswordPageComponent({
  email,
  confirmationCode,
  codeSent,
}: SetForgottenPasswordPageProps) {
  const [state, setState] = useState<SetForgottenPasswordPageState>({
    isPasswordValid: false,
    passwordValue: "",
    areQueryParametersValid: true,
    isLoading: false,
  });
  const { alert, showAlert, closeAlert } = useManagedAlert();

  useEffect(() => {
    analyticsContentViewed("set_forgotten_password");

    const areQueryParametersValid =
      validateEmail(email || "") && !!confirmationCode && !!codeSent && isFreshCode(codeSent);

    setState((prevState) => ({ ...prevState, areQueryParametersValid }));
  }, [email, confirmationCode]);

  async function onClickSubmitButton(e: Event) {
    e.preventDefault();

    setState((prevState) => ({ ...prevState, isLoading: true }));

    await eventualCognitoConfig();

    if (!state.isPasswordValid) {
      showAlert({
        headline: "Das hat leider nicht geklappt",
        description: "Bitte prüfe deine Eingaben",
        severity: "error",
      });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return;
    }

    const confirmResetPasswordInput: Auth.ConfirmResetPasswordInput = {
      username: email!,
      confirmationCode: confirmationCode!,
      newPassword: state.passwordValue!,
    };

    try {
      await Auth.confirmResetPassword(confirmResetPasswordInput);
    } catch (e) {
      const exception = e as { name: string };
      console.error(
        "resetPasswordOutput - confirmResetPassword - Exception",
        exception,
        exception.name,
      );

      let errorNotification: string;
      switch (exception.name) {
        case "CodeMismatchException":
          errorNotification =
            "Der Code in deiner E-Mail stimmt nicht mit dem in unserem System überein. Hast du die richtige E-Mail ausgewählt?";
          break;
        case "ExpiredCodeException":
          errorNotification = expirationErrorMessage;
          break;
        default:
          errorNotification = "Das hat leider nicht geklappt. Bitte versuche es später erneut.";
      }

      showAlert({
        headline: "Das hat leider nicht geklappt",
        description: errorNotification,
        severity: "error",
      });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return;
    }

    try {
      await Auth.signIn({
        username: email!,
        password: state.passwordValue!,
      });
      window.open("/c/account?passwordSuccessFullyReset=true", "_self");
    } catch (e) {
      console.error("resetPasswordOutput - signIn - Exception", e);
      showAlert({
        headline: "Das hat leider nicht geklappt",
        description: "Bitte versuche es später erneut.",
        severity: "error",
      });
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }

  function onChangePassword(isValid?: boolean, value?: string) {
    setState((prevState) => ({
      ...prevState,
      isPasswordValid: isValid,
      passwordValue: value,
    }));
    closeAlert();
  }

  return (
    <>
      <Grid>
        <div className="mono-1-tight">
          {!state.areQueryParametersValid && renderError(codeSent)}
          {state.areQueryParametersValid && (
            <>
              <Heading sizeStep={2} text="Neues Passwort vergeben" />
              <Text class={colors.blueText}>Trage hier dein neues Passwort ein</Text>
              <NewPasswordInput id="password" label="Passwort" onChange={onChangePassword} />
              <ManagedAlert alert={alert} onClose={closeAlert} />
              <Button
                class={buttonStyles.buttonFullWidth}
                onClick={onClickSubmitButton}
                level="primary"
                text="PASSWORT SPEICHERN"
                isLoading={state.isLoading}
              />
              <Button
                class={buttonStyles.buttonFullWidth}
                level="secondary"
                text="Abbrechen"
                href="/c/account/login"
              />
            </>
          )}
        </div>
      </Grid>
    </>
  );
}

function renderError(codeSent: number | undefined) {
  const tryAgain = <TextLink href="/c/account/forgot-password" text="Noch einmal versuchen" />;
  if (codeSent && !isFreshCode(codeSent)) {
    return (
      <>
        <Heading sizeStep={2} text="Passwort-Link abgelaufen" />
        <div className={margins.pulseMarginBottomSM}>
          <Alert
            type="warning"
            headline="Das hat leider nicht funktioniert"
            description={expirationErrorMessage}
            showCloseButton={false}
          />
        </div>
        {tryAgain}
      </>
    );
  } else {
    return (
      <>
        <Heading sizeStep={2} text="Neues Passwort vergeben" />
        <div className={margins.pulseMarginBottomSM}>
          <Alert
            type="error"
            headline="Ungültiger Link"
            description="Der verwendete Link ist ungültig. Versuche, erneut auf den Link in der E-Mail zu klicken, oder fordere einen neuen Link an."
            showCloseButton={false}
          />
        </div>
        {tryAgain}
      </>
    );
  }
}

export interface SetForgottenPasswordPageProps {
  email?: string;
  confirmationCode?: string;
  codeSent?: number; // UNIX timestamp in milliseconds when the confirmation code was created
}

interface SetForgottenPasswordPageState {
  isPasswordValid?: boolean;
  passwordValue?: string;
  areQueryParametersValid?: boolean;
  isLoading?: boolean;
}
