import { Component } from "preact";
import css from "./passwordSecurityIndicator.module.css";
import classNames from "classnames";

export class PasswordSecurityIndicator extends Component<
  PasswordSecurityIndicatorProps,
  Record<string, never>
> {
  render() {
    let message: string;
    let messageClasses = css.indicationBarMessage;
    let firstSegmentClasses = css.indicationBarElement;
    let secondSegmentClasses = css.indicationBarElement;
    let thirdSegmentClasses = css.indicationBarElement;

    switch (true) {
      case this.props.securityLevel >= 1:
        message = "Passwortsicherheit: Hoch";
        messageClasses += ` ${css.indicationBarMessageSuccess}`;
        firstSegmentClasses += ` ${css.indicationBarElementSuccess}`;
        secondSegmentClasses += ` ${css.indicationBarElementSuccess}`;
        thirdSegmentClasses += ` ${css.indicationBarElementSuccess}`;
        break;
      case this.props.securityLevel >= 0.75:
        message = "Passwortsicherheit: Mittel";
        messageClasses += ` ${css.indicationBarMessageWarning}`;
        firstSegmentClasses += ` ${css.indicationBarElementWarning}`;
        secondSegmentClasses += ` ${css.indicationBarElementWarning}`;
        thirdSegmentClasses += ` ${css.indicationBarElementDefault}`;
        break;
      case this.props.securityLevel < 0.75 && this.props.securityLevel > 0:
        message = "Passwortsicherheit: Gering";
        messageClasses += ` ${css.indicationBarMessageError}`;
        secondSegmentClasses += ` ${css.indicationBarElementDefault}`;
        thirdSegmentClasses += ` ${css.indicationBarElementDefault}`;
        break;
      default:
        message = "Passwortsicherheit: Bitte gib ein Passwort ein";
        messageClasses += ` ${css.indicationBarMessageDefault}`;
        firstSegmentClasses += ` ${css.indicationBarElementDefault}`;
        secondSegmentClasses += ` ${css.indicationBarElementDefault}`;
        thirdSegmentClasses += ` ${css.indicationBarElementDefault}`;
        break;
    }

    return (
      <div class={classNames(this.props.class)}>
        <div class={messageClasses}>{message}</div>
        <div class={css.indicationBar}>
          <div class={firstSegmentClasses}></div>
          <div class={secondSegmentClasses}></div>
          <div class={thirdSegmentClasses}></div>
        </div>
      </div>
    );
  }
}

export interface PasswordSecurityIndicatorProps {
  class: string | string[] | Record<string, boolean>;
  securityLevel: number;
}
