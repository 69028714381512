import { Alert } from "@idg-web-platform/pulse";
import { JSX } from "react";
import margins from "@idg-web-platform/pulse/cssModules/margins.module.css";
import classNames from "classnames";

export interface ManagedAlertProps {
  alert: AlertState | null;
  onClose: () => void;
  removeMargin?: boolean;
}

export interface AlertState {
  severity: "success" | "warning" | "error" | "info";
  headline: string;
  description: string;
}

export function ManagedAlert({
  alert,
  onClose,
  removeMargin = false,
}: ManagedAlertProps): JSX.Element | null {
  if (!alert) return null;

  const { severity, headline, description } = alert;

  return (
    <div className={classNames({ [margins.pulseMarginBottomM]: !removeMargin })}>
      <Alert
        type={severity}
        headline={headline}
        description={description}
        showCloseButton
        onClose={onClose}
      />
    </div>
  );
}
