import { Component } from "preact";
import css from "./newPasswordInput.module.css";
import { PasswordSecurityIndicator } from "../passwordSecurityIndicator/passwordSecurityIndicator.tsx";
import classNames from "classnames";
import { Input } from "@idg-web-platform/pulse";

export class NewPasswordInput extends Component<NewPasswordInputProps, NewPasswordInputState> {
  componentDidMount() {
    this.setCurrentPassword(this.props?.value ?? undefined);
    this.setRepeatedPassword(this.props?.value ?? undefined);
  }

  async forceValidate(): Promise<boolean> {
    await this.setCurrentPassword(this.state.currentPassword || "");
    await this.setRepeatedPassword(this.state.repeatedPassword || "");
    return (this.state.isCurrentPasswordValid && this.state.isRepeatedPasswordValid) ?? false;
  }

  async setCurrentPassword(currentPassword: string | undefined): Promise<void> {
    const isMinimumLength = currentPassword ? currentPassword.length >= 8 : false;
    const hasUpperCaseLetter = /[A-Z]/.test(currentPassword || "");
    const hasLowerCaseLetter = /[a-z]/.test(currentPassword || "");
    const hasSpecialCharacter = /[*.{}()?"!@#%&,><':;|_~`=+\-^$/\\[\]]/.test(currentPassword || "");
    const isPasswordSecure =
      isMinimumLength && hasUpperCaseLetter && hasLowerCaseLetter && hasSpecialCharacter;

    const passwordSecurityLevel =
      (Number(isMinimumLength) +
        Number(hasUpperCaseLetter) +
        Number(hasLowerCaseLetter) +
        Number(hasSpecialCharacter)) /
      4;

    let isCurrentPasswordValid = true;
    let invalidHintCurrentPassword: string | undefined;

    if (currentPassword === undefined) {
      isCurrentPasswordValid = true;
    } else if (currentPassword.length === 0) {
      isCurrentPasswordValid = false;
      invalidHintCurrentPassword = "Bitte gib ein Passwort ein";
    } else if (!isPasswordSecure) {
      isCurrentPasswordValid = false;
      invalidHintCurrentPassword = "Das Passwort erfüllt leider nicht die Vorgaben";
    }

    await new Promise<void>((resolve) =>
      this.setState(
        {
          currentPassword,
          isMinimumLength,
          hasUpperCaseLetter,
          hasLowerCaseLetter,
          hasSpecialCharacter,
          isCurrentPasswordValid,
          invalidHintCurrentPassword,
          passwordSecurityLevel,
        },
        resolve,
      ),
    );
  }

  async setRepeatedPassword(repeatedPassword: string | undefined): Promise<void> {
    await new Promise<void>((resolve) =>
      this.setState(
        {
          repeatedPassword,
        },
        resolve,
      ),
    );
    this.validateRepeatedPassword();
  }

  private validateRepeatedPassword() {
    let isRepeatedPasswordValid = true;
    let invalidHintRepeatedPassword: string | undefined;

    if (this.state.repeatedPassword === undefined) {
      isRepeatedPasswordValid = true;
    } else if ((this.state.repeatedPassword?.length ?? 0) === 0) {
      isRepeatedPasswordValid = false;
      invalidHintRepeatedPassword = "Bitte wiederhole dein Passwort";
    } else if (this.state.currentPassword !== this.state.repeatedPassword) {
      isRepeatedPasswordValid = false;
      invalidHintRepeatedPassword = "Leider stimmen die beiden Passwörter nicht überein";
    }

    this.setState({
      isRepeatedPasswordValid,
      invalidHintRepeatedPassword,
    });

    this.props.onChange?.(
      this.state.isCurrentPasswordValid && isRepeatedPasswordValid,
      this.state.currentPassword,
    );
  }

  private onInputPassword(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    this.setCurrentPassword(target.value);
  }

  private onInputPasswordRepetition(event: InputEvent) {
    const target = event.target as HTMLInputElement;
    this.setRepeatedPassword(target.value);
  }

  render() {
    return (
      <div>
        <Input
          id={this.props.id}
          label={this.props.label}
          type="password"
          autocomplete="new-password"
          value={this.state.currentPassword}
          valid={this.state.isCurrentPasswordValid}
          errorMessage={this.state.invalidHintCurrentPassword}
          onInput={this.onInputPassword.bind(this)}
          required
        />
        <PasswordSecurityIndicator
          class={classNames({
            [css.newPasswordInputSecurityIndicator]: true,
          })}
          securityLevel={this.state.passwordSecurityLevel ?? 0}
        />
        <div class={css.topLine}>
          Mindestanforderungen für ein <strong>sicheres Passwort</strong>:
        </div>
        <ul>
          <li
            class={css.checkPoint + (this.state.isMinimumLength ? ` ${css.checkPointValid}` : "")}
          >
            8 Zeichen
          </li>
          <li
            class={
              css.checkPoint +
              (this.state.hasUpperCaseLetter && this.state.hasLowerCaseLetter
                ? ` ${css.checkPointValid}`
                : "")
            }
          >
            Einen Großbuchstaben und einen Kleinbuchstaben
          </li>
          <li
            class={
              css.checkPoint + (this.state.hasSpecialCharacter ? ` ${css.checkPointValid}` : "")
            }
          >
            Ein Sonderzeichen
          </li>
        </ul>
        <Input
          id={`${this.props.id}_repetition`}
          label={`${this.props.label} wiederholen`}
          type="password"
          autocomplete="new-password"
          value={this.state.repeatedPassword}
          valid={this.state.isRepeatedPasswordValid}
          errorMessage={this.state.invalidHintRepeatedPassword}
          onInput={this.onInputPasswordRepetition.bind(this)}
          required
        />
      </div>
    );
  }
}

export interface NewPasswordInputProps {
  id: string;
  label: string;
  value?: string;
  onChange?: (isValid?: boolean, value?: string) => void;
}

interface NewPasswordInputState {
  currentPassword?: string | undefined;
  isCurrentPasswordValid?: boolean;
  invalidHintCurrentPassword?: string;
  isMinimumLength?: boolean;
  hasUpperCaseLetter?: boolean;
  hasLowerCaseLetter?: boolean;
  hasSpecialCharacter?: boolean;
  passwordSecurityLevel?: number;
  repeatedPassword?: string | undefined;
  isRepeatedPasswordValid?: boolean;
  invalidHintRepeatedPassword?: string;
}
