import { useState } from "preact/hooks";
import { AlertState } from "./managedAlert.tsx";

export function useManagedAlert() {
  const [alert, setAlert] = useState<AlertState | null>(null);

  function showAlert(newAlert: AlertState) {
    setAlert(newAlert);
  }

  function closeAlert() {
    setAlert(null);
  }

  return { alert, showAlert, closeAlert };
}
