import { define } from "preactement";

import { LoginDataPage } from "./views/registration/loginDataPage.tsx";
import { InvoiceAddressPage } from "./views/registration/invoiceAddressPage.tsx";
import { LoginPage } from "./views/login/loginPage.tsx";
import { MyAccount } from "./views/myAccount/myAccount.tsx";
import { LogoutPage } from "./views/login/logoutPage.tsx";

import { LoginIcon } from "./views/loginIcon/loginIcon.tsx";
import { LoginForm } from "./views/login/loginForm.tsx";

import { configureCognito } from "./config/cognito.ts";
import { RequestResetMailPage } from "./views/passwordForgotten/requestResetMailPage.tsx";
import { SetPasswordPage } from "./views/passwordForgotten/setPasswordPage.tsx";
import { EmailSentPage } from "./views/passwordForgotten/emailSentPage.tsx";
import { DeletePreliminaryAccountEventListenerComponent } from "./views/registration/deletePreliminaryAccountEventListenerComponent.tsx";
import { DataPage } from "./views/myAccount/dataPage.tsx";
import { Navigation } from "./components/navigation/navigation.tsx";
import { ContactPage } from "./views/contact/contactPage.tsx";
import { HelpPage } from "./views/help/helpPage.tsx";
import { trackControlAccount } from "./config/tracking.ts";

// APIs

define(LoginIcon.tagName, () => LoginIcon);
define(LoginForm.tagName, () => LoginForm, { attributes: ["redirect"] });
define(Navigation.tagName, () => Navigation, { attributes: ["currentPath"] });

// Own components

define(RequestResetMailPage.tagName, () => RequestResetMailPage);
define(SetPasswordPage.tagName, () => SetPasswordPage);
define(InvoiceAddressPage.tagName, () => InvoiceAddressPage);
define(LoginPage.tagName, () => LoginPage);
define(MyAccount.tagName, () => MyAccount);
define(LogoutPage.tagName, () => LogoutPage);
define(
  DeletePreliminaryAccountEventListenerComponent.tagName,
  () => DeletePreliminaryAccountEventListenerComponent,
);
define(LoginDataPage.tagName, () => LoginDataPage);
define(EmailSentPage.tagName, () => EmailSentPage);
define(DataPage.tagName, () => DataPage);
define(ContactPage.tagName, () => ContactPage);
define(HelpPage.tagName, () => HelpPage);

declare global {
  interface Window {
    configureCognito: typeof configureCognito;
    trackControlAccount: typeof trackControlAccount;
  }
}

window.configureCognito = configureCognito;
window.trackControlAccount = trackControlAccount;
