import { useEffect } from "preact/hooks";
import {
  deletePreliminaryAccountEventListener,
  deletePreliminaryAccountEventListenerType,
} from "../../functions/deletePreliminaryAccountEventListener.ts";

DeletePreliminaryAccountEventListenerComponent.tagName =
  "control-window-event-listener--delete-preliminary-account";

export function DeletePreliminaryAccountEventListenerComponent() {
  useEffect(() => {
    window.addEventListener(
      deletePreliminaryAccountEventListenerType,
      deletePreliminaryAccountEventListener,
    );
    return () => {
      window.removeEventListener(
        deletePreliminaryAccountEventListenerType,
        deletePreliminaryAccountEventListener,
      );
    };
  }, []);

  return <></>;
}
