import { LoginForm } from "./loginForm.tsx";
import { Button, Grid, Heading, IconClub, Text } from "@idg-web-platform/pulse";
import css from "./login.module.css";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect } from "preact/hooks";

LoginPage.tagName = "control-login-page";

export function LoginPage({ redirect }: LoginPageProps) {
  useEffect(() => {
    analyticsContentViewed("login");
  }, []);

  return (
    <Grid>
      <div className="mono-1-narrow">
        <Heading sizeStep={2} text="Anmelden" />
      </div>

      <LoginForm redirect={redirect} className={"double-1_1-narrow-left"} />

      <div className={`${css.box} ${css.registrationBox} double-1_1-narrow-right`}>
        <div class={css.content}>
          <Text>
            <strong>Neu</strong> bei INTERSPORT
          </Text>

          <Text fontStyle={"bold"}>Kundenkonto - alles im Blick:</Text>
          <ul>
            <li>Deine Bestellungen</li>
            <li>Persönliche Daten</li>
          </ul>

          <Text fontStyle={"bold"} className={css.iconText}>
            <div className={css.clubIcon}>
              <IconClub fluid={true} />
            </div>
            CLUB Vorteile:
          </Text>
          <ul>
            <li>Kostenloser Versand</li>
            <li>5 € Willkommens-Bonus</li>
            <li>Punkte sammeln und einlösen</li>
          </ul>
        </div>
        <div class={css.buttonContainer}>
          <Button
            class={css.button}
            level="secondary"
            outline={true}
            text="Kundenkonto anlegen"
            href="/c/account/registration"
          />
        </div>
      </div>
    </Grid>
  );
}

export interface LoginPageProps {
  redirect: string;
}
