import * as Auth from "@aws-amplify/auth";
import { Button, Grid, Heading, Input, Text } from "@idg-web-platform/pulse";
import buttonStyles from "../../styles/buttonStyles.module.css";
import { eventualCognitoConfig } from "../../config/cognito.ts";
import colors from "../../styles/colors.module.css";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect, useState } from "preact/hooks";
import { validateEmail } from "../../functions/validateFormFields.ts";
import { useManagedAlert } from "../../components/managedAlert/useManagedAlert.ts";
import { ManagedAlert } from "../../components/managedAlert/managedAlert.tsx";
import css from "./requestResetMailPage.module.css";

RequestResetMailPage.tagName = "control-forgot-password-page";

export function RequestResetMailPage(
  requestPasswordResetMailPageProps: RequestPasswordResetMailPageProps,
) {
  return <RequestPasswordResetMailPageComponent {...requestPasswordResetMailPageProps} />;
}

function RequestPasswordResetMailPageComponent({ email }: RequestPasswordResetMailPageProps) {
  const [state, setState] = useState<RequestPasswordResetMailPageState>({});
  const { alert, showAlert, closeAlert } = useManagedAlert();

  useEffect(() => {
    analyticsContentViewed("request_password_reset_mail");
    setState((prevState) => ({
      ...prevState,
      emailAddress: email ?? undefined,
    }));
  }, [email]);

  function onChangeEmail(value: string, validate = true) {
    setState((prevState) => ({
      ...prevState,
      isEmailValid: validate ? validateEmail(value) : true,
      emailAddress: value.trim().toLowerCase(),
    }));
    closeAlert();
  }

  async function onClickSubmitButton(e: Event) {
    e.preventDefault();

    if (!state.emailAddress) {
      onChangeEmail("");
      return;
    }

    setState((prevState) => ({ ...prevState, isLoading: true }));

    await eventualCognitoConfig();

    try {
      const output: Auth.ResetPasswordOutput | void = await Auth.resetPassword({
        username: state.emailAddress,
      });

      if (output?.nextStep.resetPasswordStep !== "CONFIRM_RESET_PASSWORD_WITH_CODE") {
        console.error(
          `Next Step '${output?.nextStep.resetPasswordStep}' could not be processed`,
          output,
        );
        throw new Error("Invalid next step in reset password process.");
      }

      document.location.href = "/c/account/forgot-password-email-sent";
    } catch (err) {
      console.error(err);
      showAlert({
        headline: "Das hat leider nicht geklappt",
        description: "Bitte versuche es später erneut.",
        severity: "error",
      });
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }

  return (
    <>
      <Grid>
        <div className={css.pageContainer}>
          <ManagedAlert alert={alert} onClose={closeAlert} />
          <Heading sizeStep={2} text="Passwort Zurücksetzen" />
          <Text class={colors.blueText}>
            Bitte gib die E-Mail-Adresse ein, die mit deinem INTERSPORT-Konto verknüpft ist. So
            können wir dir einen Link zum Zurücksetzen schicken.
          </Text>

          <Input
            id="email"
            label="E-Mail-Adresse"
            type="email"
            onInput={(e) => onChangeEmail(e.currentTarget.value, false)}
            value={state.emailAddress}
            valid={state.isEmailValid}
            errorMessage="Bitte gib eine gültige E-Mail-Adresse ein."
            required
          />

          <Button
            class={buttonStyles.buttonFullWidth}
            onClick={onClickSubmitButton}
            level="primary"
            text="Link anfordern"
            isLoading={state.isLoading}
          />

          <Button
            class={buttonStyles.buttonFullWidth}
            level="secondary"
            text="Abbrechen"
            href="/c/account/login"
          />
        </div>
      </Grid>
    </>
  );
}

export interface RequestPasswordResetMailPageProps {
  email?: string;
}

interface RequestPasswordResetMailPageState {
  emailAddress?: string;
  isEmailValid?: boolean;
  isLoading?: boolean;
}
