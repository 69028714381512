import { LoginForm } from "./loginForm.tsx";
import { Grid, Heading } from "@idg-web-platform/pulse";
import { analyticsContentViewed } from "../../utils/analyticsContentViewed.ts";
import { useEffect } from "preact/hooks";
import css from "./logout.module.css";
import { ManagedAlert } from "../../components/managedAlert/managedAlert.tsx";
import { useManagedAlert } from "../../components/managedAlert/useManagedAlert.ts";

LogoutPage.tagName = "control-logout-page";

export function LogoutPage({ redirect }: LogoutPageProps) {
  const { alert, showAlert, closeAlert } = useManagedAlert();

  useEffect(() => {
    analyticsContentViewed("logout");
    showAlert({
      severity: "success",
      headline: "Abmeldung erfolgreich",
      description: "Du hast dich erfolgreich abgemeldet.",
    });
  }, []);

  return (
    <>
      <Grid>
        <div className={css.logoutPageContainer}>
          <div className="mono-1-narrow">
            <Heading sizeStep={2} text="Willkommen zurück" />
          </div>
          <ManagedAlert alert={alert} onClose={closeAlert} />
          <LoginForm redirect={redirect} />
        </div>
      </Grid>
    </>
  );
}

export interface LogoutPageProps {
  redirect: string;
}
