import { HeaderIcon, IconCheck, IconPerson } from "@idg-web-platform/pulse";
import * as Auth from "@aws-amplify/auth";
import { withCognitoConfig } from "../../config/cognito.ts";
import { useEffect, useState } from "preact/hooks";

LoginIcon.tagName = "control-login-icon";

/**
 * This is the login icon component indicating the login state in the header.
 */
export function LoginIcon() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    withCognitoConfig(() => {
      Auth.getCurrentUser()
        .then(() => {
          setIsLoggedIn(true);
        })
        .catch(() => {
          setIsLoggedIn(false);
        });
    });
  }, []);

  if (isLoggedIn) {
    return (
      <HeaderIcon
        iconElement={<IconPerson />}
        badge={<IconCheck />}
        href={"/c/account"}
        ariaLabel={"Mein Konto"}
      />
    );
  }

  return (
    <HeaderIcon iconElement={<IconPerson />} href={"/c/account/login"} ariaLabel={"Anmelden"} />
  );
}
