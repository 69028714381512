import { Button, IconArrowRight, IconLogout, IconPerson } from "@idg-web-platform/pulse";
import css from "./navigationDesktop.module.css";
import { NavItems } from "./navigation.tsx";
import classNames from "classnames";

const NavigationDesktop = (props: DesktopNavigationProps) => {
  return (
    <div className={css.root}>
      <Button
        class={classNames(css.navItem, css.navItemMain)}
        text="Mein Intersport"
        type="button"
        level="secondary"
        iconComponent={<IconPerson />}
        href="/c/account"
      />

      <div className={css.navItemsContainer} data-testid="navigation">
        {props.navItems.map((navItem) => (
          <Button
            href={navItem.path}
            text={navItem.name}
            iconComponent={<IconArrowRight />}
            iconPosition="right"
            class={classNames({
              [css.navItem]: true,
              [css.navItemRegular]: navItem.path !== props.currentPath,
            })}
          >
            {navItem.name}
          </Button>
        ))}
      </div>

      <Button
        class={classNames(css.navItem, css.navItemLogout)}
        iconPosition={"right"}
        text="Abmelden"
        type="button"
        level="secondary"
        iconComponent={<IconLogout />}
        onClick={props.logoutHandler}
        isLoading={props.isSigningOut}
      />
    </div>
  );
};

interface DesktopNavigationProps {
  navItems: NavItems;
  currentPath?: string;
  logoutHandler: () => void;
  isSigningOut: boolean;
}

export { NavigationDesktop };
