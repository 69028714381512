import {
  deletePreliminaryAccount,
  DeletePreliminaryAccountEventListenerStatus,
} from "./deletePreliminaryAccount.ts";

export interface DeletePreliminaryAccountEventListenerDetail {
  resolve: (result: DeletePreliminaryAccountEventListenerStatus) => void;
  reject: (e: Error) => void;
}

export const deletePreliminaryAccountEventListenerType = "control-delete-preliminary-account";

export const deletePreliminaryAccountEventListener: (event: Event) => Promise<void> = async (
  event,
) => {
  const {
    detail: { resolve, reject },
  } = event as CustomEvent<DeletePreliminaryAccountEventListenerDetail>;

  try {
    const result = await deletePreliminaryAccount();
    resolve(result);
  } catch (e) {
    reject(e as Error);
  }
};
